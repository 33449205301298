<template>
    <div class="step13 mt-5">
        <TitleSurvey :title="'KẾ HOẠCH TỐT NHẤT DÀNH CHO BẠN'" />
        <div class="container">
            <div class="row" style="justify-content: end">
                <div class="col-sm-2 mr-md-4">
                    <img :src="image_url" class="img1" />
                </div>
                <div class="col-sm-7 ml-md-4 plan-detail">
                    <img src="@/assets/img/step13/img3.svg" class="img3" />
                    <div class="user">
                        {{ subscriber }}+ người dùng đã tham gia
                    </div>
                    <hr class="vertical-line" />
                    <div class="plan-name">{{ name }}</div>
                    <div class="training"><img src="@/assets/img/step13/img4.svg" class="img4" /> {{ plan_time }} tuần
                    </div>
                    <div class="movement"><img src="@/assets/img/step13/img5.svg" class="img5" /> {{ calorie }} Kcal
                    </div>
                    <div class="price">
                        GIÁ CHỈ: <span>{{ price_plan }} đồng</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Xem chi tiết"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import TitleSurvey from '@/components/TitleSurvey.vue'
import { getFromLocalStorage } from '@/utils/localStorage'
import { getFromSessionStorage } from '@/utils/session'

export default {
    name: 'StepThirteen',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            planData: [],
            image_url: null,
            name: '',
            subscriber: '',
            price_plan: '',
            target_name: '',
            plan_time: '',
            calorie: '',
        }
    },
    beforeMount() {
        this.user = getFromSessionStorage('user')
    },

    mounted() {
        this.suggestPlan()
    },
    methods: {
        async suggestPlan() {
            try {
                let storeSurvey = getFromLocalStorage('surveyData')
                const request_Params = {
                    'phone-number': this.user.userInfoIdentifing.phoneNumber
                }
                const response = await sendRequest(
                    Api.survey.suggestPlan,
                    null,
                    null,
                    request_Params
                )

                const dataPlan =
                    response.item.find((item) => item.image_url) || {}

                const {
                    image_url = '',
                    name = '',
                    subscriber = '',
                    price_plan = '',
                    target_name = '',
                    plan_time = '',
                    calorie = '',
                } = dataPlan

                this.image_url = image_url
                this.name = name
                this.subscriber = subscriber
                this.price_plan = price_plan
                this.target_name = target_name
                this.calorie = calorie
                this.plan_time = plan_time
            } catch (error) {
                console.log(error)
            }
        },
        BackStep() {
            this.$router.push('/analysis')
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$router.push('/plan-detail')
            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.plan-detail .training img {
    width: 30px;
}

.plan-detail .movement img {
    width: 30px;
}


.step13 {
    height: 100vh;
}

.img1 {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}

.img2 {
    position: absolute;
    left: 45%;
    top: 90%;
    object-fit: cover;
    width: 50%;
}

.img3 {
    object-fit: cover;
}

.user {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fcf9f4;
    font-weight: 600;
}

.vertical-line {
    border: 1px solid #fcf9f4;
    width: 75%;
}

.plan-name {
    margin-top: 30px;
    font-size: 24px;
    color: #fcf9f4;
    font-weight: 900;
    letter-spacing: 5px;
}

.training,
.movement {
    margin-top: 10px;
    color: #fcf9f4;
    font-size: 20px;
    font-weight: 700;
}

.price {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 700;
    color: #fdb209;
}

.price span {
    font-size: 32px;
    font-weight: 900;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}

.btn {
    padding: 13px 120px;
    border-radius: 8px;
}
</style>

<template>
    <div class="bg">
        <div class="container">
            <div class="pt-5 mb-5 layout-common text-center">
                <img src="@/assets/img/Asset 1 1.png" class="img" />

                <img src="@/assets/img/VFITNESS.svg" class="img" />
            </div>

            <div class="mb-5 layout-common text-center">
                <img src="@/assets/img/hr.svg" class="img-hr" />
            </div>
            <div class="layout-common text-center">
                <h4>Tạo mật khẩu</h4>
            </div>

            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="layout-common mb-2 mt-4">
                        <div> <label style="color: #ffffff">Mật khẩu mới</label></div>
                        <PasswordInput
                                style="width: 100%"
                                v-model="password"
                                placeholder="Nhập mật khẩu mới"
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                                @input="handleInputChange('password')"
                        >
                        </PasswordInput>
                    </div>

                    <div class="container" v-if="passwordError">
                        <div class="d-flex justify-content-center">
                            <div class=" err">
                                <h6>{{ passwordError }}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="layout-common mb-2 mt-4">
                        <div><label style="color: #ffffff">Nhập lại mật khẩu</label></div>
                        <PasswordInput
                                style="width: 100%"
                                v-model="retypePassword"
                                placeholder="Nhập lại mật khẩu"
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                                @input="handleInputChange('retypePassword')"
                        >
                        </PasswordInput>
                    </div>
                    <div class="container" v-if="retypePasswordError">
                        <div class="d-flex justify-content-center">
                            <div class="err">
                                <h6>{{ retypePasswordError }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>

            <div class="layout-common pt-4 text-center">
                <p>Vui lòng nhập mật khẩu cho số điện thoại</p>
            </div>
            <div class="layout-common mt-1 text-center">
                <h5>{{ this.queryParams.phone || this.sdt }}</h5>
            </div>
        </div>
        <div class="layout-common mt-4 padding-80 text-center">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn bg-while"
                @click="navigateSignUp"
            />
            <CustomButton
                label="Đăng ký"
                size="medium"
                type="text"
                class="btn bg-red"
                @click="handleGeneratePassword"
            />
        </div>

        <ElDialog
            :title="'Thông báo'"
            :message="messageDialog"
            :visible.sync="dialogVisible"
            :showButton="false"
        />
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import sendRequest from '@/services'
import Api from '@/constants/Api'
import { mapState } from 'vuex'
import cryptoJs from 'crypto-js'
import { path } from '@/router/path'
import { validateInput, changeValidateInput } from '@/utils/common'
import ElDialog from '@/components/ElDialog.vue'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'
export default {
    name: 'PasswordGenerator',
    components: { CustomButton, PasswordInput, ElDialog },
    data() {
        return {
            queryParams: {},

            password: '',
            retypePassword: '',
            passwordError: '',
            retypePasswordError: '',
            messageDialog: '',
            dialogVisible: false
        }
    },

    mounted() {

        this.queryParams = this.$route.query;
    },

    methods: {
        validate() {
            const passwordError = validateInput('password', this.password)
            const retypePasswordError = validateInput(
                'retypePassword',
                this.retypePassword,
                this.password
            )
            if (passwordError || retypePasswordError) {
                this.passwordError = passwordError
                this.retypePasswordError = retypePasswordError
                return false
            } else {
                this.passwordError = ''
                this.retypePasswordError = ''
                return true
            }
        },
        handleInputChange(fieldName) {
            if (this.passwordError !== '' || this.retypePasswordError !== '') {
                changeValidateInput(
                    fieldName,
                    this[fieldName],
                    this.password,
                    this
                )
            }
        },
        async navigateSignUp() {
            this.$router.push(path.auth.signup)
        },

        async handleGeneratePassword() {
            const isValid = this.validate()
            if (isValid) {
                const password = this.password.toString()



                const requestData = {
                    password: password,
                    sessionId: this.queryParams.sessionId || this.sessionId,
                    deviceId: (Date.now()).toString()
                }
                const response = await sendRequest(
                    Api.auth.signup.createUser,
                    requestData
                )
                if (response.code === 200) {
                    this.passwordError = ''
                    this.dialogVisible = true
                    this.messageDialog = 'Đăng ký thành công'

                    saveToSessionStorage('isLogin', true)
                    saveToSessionStorage('user', response)

                    this.$router.push({
                        path: path.auth.success
                    })
                }else{
                    this.passwordError = response.message
                }
                console.log(response)
            }
        }
    },
    computed: {
        ...mapState(['sessionId', 'sdt'])
    }
}
</script>

<style scoped>
.bg {
    background: #303030 url(@/assets/img/background.svg) center/cover no-repeat;
    height: 100vh;
}
.container {
    padding-left: 0px;
    padding-right: 0px;
}
.layout-common {

}
.img-hr {
    object-fit: cover;
    width: 100%;
}

.layout-common h4 {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
}
.layout-common h5 {
    color: #fdb209;
    font-weight: 500;
    font-size: 18px;
}
.layout-common p {
    color: #ffffff;
    margin-bottom: 0;
    /*font-family: cursive, sans-serif;*/
}
.bg-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}
.bg-while {
    color: #303030;
    background-color: #ffffff;
    font-weight: 700;
}

.btn {
    margin: 0px 20px 0px 20px;
    padding: 13px 120px;
    border-radius: 8px;
}
.padding-80 {
    padding-bottom: 80px;
}
.err {

}
.err h6 {
    color: red;
    font-size: 16px;
    font-style: italic;
}
</style>

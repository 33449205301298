const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

const Api = {
    auth: {
        login: {
            method: METHOD.POST,
            url: 'authentication/login/mail-or-phone'
        },
        signup: {
            authentication: {
                method: METHOD.POST,
                url: 'authentication/sign-up'
            },
            getOtp: {
                method: METHOD.POST,
                url: 'authentication/sign-up/get-otp'
            },
            verification: {
                method: METHOD.POST,
                url: 'authentication/sign-up/verification'
            },
            createUser: {
                method: METHOD.PUT,
                url: 'authentication/sign-up'
            }
        }
    },
    planIntro: {
        getListData: {
            method: METHOD.GET,
            url: 'plan/intro/admin'
        }
    },
    survey: {

        getMovement: {
            method: METHOD.GET,
            url: 'plan/screen-config/movement'
        },
        createSurvey: {
            method: METHOD.POST,
            url: 'plan/survey'
        },
        getListTarget: {
            method: METHOD.GET,
            url: 'plan/target'
        },
        getLickSick: {
            method: METHOD.GET,
            url: 'plan/sick'
        },
        getListEquipment: {
            method: METHOD.GET,
            url: 'plan/planing-equipment'
        },
        suggestPlan: {
            method: METHOD.GET,
            url: 'plan/plan-user/suggest-plan-v2'
        },
        suggestSurvey: { method: METHOD.GET, url: 'plan/survey' },
        confirmPay: {
            method: METHOD.PUT,
            url: 'plan/survey/update-plan'
        },

        planPackage: {
            method: METHOD.GET,
            url: 'user/pay/plan-package'
        },

        getPricePackage: {
            url: 'user/pay/calculate-price',
            method: METHOD.POST
        },
        confirmPayFinal: {
            method: METHOD.PUT,
            url: 'plan/plan-user/confirm-paid'
        },
        listPlanTime: {
            method: METHOD.GET,
            url: '/plan/admin-screen-config'
        }
    },
    payment: {
        getQr: {
            url: 'user/pay/receive-payment',
            method: METHOD.POST

        },
        payment: {
            url: 'user/pay/confirm-payment',
            method: METHOD.POST
        }
    }
}

export default Api

export const validateField = (component, field, isValid) => {
    switch (field.name) {
        case 'hoTen': {
            if (field.value === undefined) {
                component[field.errorField] = 'Vui lòng nhập họ tên!'
                return false
            }
            break
        }
        case 'birthDay': {
            const currentYear = new Date().getFullYear()

            const birthDate = new Date(field.value)

            if (
                isNaN(birthDate.getTime()) ||
                birthDate.getFullYear() > currentYear ||
                field.value === null
            ) {
                component[field.errorField] = 'Vui lòng nhập ngày sinh!'
                return false
            }
            break
        }

        case 'email': {
            // Kiểm tra xem người dùng đã nhập hay chưa
            if (!field.value) {
                component[field.errorField] = '' // Đặt thông báo lỗi về rỗng
                break // Không cần thực hiện validate nếu không có giá trị
            }

            // Người dùng đã nhập, tiến hành validate
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            if (!emailRegex.test(field.value)) {
                component[field.errorField] =
                    'Vui lòng đúng định dạng email ví dụ abc@gmail.com!'
                return false
            }
            break
        }

        case 'phoneNumber': {
            const phoneNumberRegex = /^(0\d{9})$/
            if (!field.value || !phoneNumberRegex.test(field.value)) {
                component[field.errorField] =
                    'Vui lòng nhập số điện thoại bao gồm 10 chữ số bắt đầu bằng 0!'
                return false
            }
            break
        }

        case 'password': {
            if (
                field.value &&
                field.value !== null &&
                field.value !== undefined
            ) {
                const passwordRegex =
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                if (!passwordRegex.test(field.value)) {
                    component[field.errorField] =
                        'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, chữ số và ký tự đặc biệt'
                    return false
                }
            }
            break
        }

        case 'password_retype': {
            const passwordValue = component['password']
            if (passwordValue !== '' && field.value !== passwordValue) {
                component[field.errorField] = 'Mật khẩu nhập lại không khớp'
                return false
            }
            break
        }

        default:
            break
    }

    component[field.errorField] = ''
    return isValid
}

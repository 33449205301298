<template>
    <div class="step2">
        <TitleSurvey :title="'Cân nặng | Chiều cao'" />
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-sm-5">
                    <div class="form form-kg">
                        <ElInputNumber
                            v-model="weight"
                            :min="30"
                            :max="300"
                        ></ElInputNumber>

                        <div class="title-form">kg</div>
                    </div>
                    <div>
                        <img src="../../assets/img/step2/img1.svg" class="img" />
                    </div>
                    <div>
                        <div class="bmi">
                            <div class="title-bmi">
                                CHỈ SỐ BMI
                                <el-tooltip
                                    class="item"
                                    effect="light"
                                    content="Body mass index (BMI): sự đo lường giữa tỉ lệ cân nặng và chiều cao để đánh giá mức  độ tăng cân và rủi ro sức khỏe liên quan đến cân nặng  "
                                    placement="top"
                                >
                                    <i class="el-icon-info"></i>
                                </el-tooltip>
                            </div>

                            <ElInputNumber
                                v-model="bmi"
                                class="input-bmi"
                                :min="10"
                                :max="300"
                                :width="100"
                                :widthUnit="'%'"
                                :disabled="true"
                            ></ElInputNumber>
                        </div>
                        <div class="row mt-4 justify-content-end">
                            <div
                                v-for="(item, index) in bmiData"
                                :key="index"
                                class="bmi-container"
                            >
                                <hr :class="item.hrClass" />

                                <div v-if="item.values" class="bmi-values">
                                    <div
                                        v-for="(value, subIndex) in item.values"
                                        :key="subIndex"
                                        class="value-bmi"
                                    >
                                        {{ value }}
                                    </div>
                                </div>

                                <div v-else class="value-bmi">
                                    {{ item.value }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="row mt-4 justify-content-end align-items-center"
                        >
                            <div
                                :class="['circle', getCircleColorClass(bmi)]"
                            ></div>
                            <div class="text-value-bmi">
                                {{ getBMIStatus(bmi) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 text-center">
                    <img
                        src="@/assets/img/step2/img2.svg"
                        style="height: 500px; width: 100%"
                    />
                </div>
                <div class="col-sm-5">
                    <div class="form">
                        <ElInputNumber
                            v-model="height"
                            :min="50"
                            :max="250"
                        ></ElInputNumber>
                        <div class="title-form">cm</div>
                    </div>
                    <img src="@/assets/img/step2/img3.svg" class="img" />
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Tiếp tục"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import ElInputNumber from '@/components/ElInputNumber.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'

export default {
    name: 'StepTwo',
    components: {
        CustomButton,
        ElInputNumber,
        TitleSurvey
    },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            weight: 50,
            height: 150,
            bmi: 0,
            bmiData: [
                { hrClass: 'hr-bmi hr-bmi-1', value: '15' },
                { hrClass: 'hr-bmi hr-bmi-2', value: '16' },
                { hrClass: 'hr-bmi hr-bmi-3', value: '18.5' },
                { hrClass: 'hr-bmi hr-bmi-4', value: '25' },
                { hrClass: 'hr-bmi hr-bmi-5', value: '30' },
                { hrClass: 'hr-bmi hr-bmi-6', values: ['35', '40'] }
            ]
        }
    },
    watch: {
        weight: 'calculateBMI',
        height: 'calculateBMI'
    },
    mounted() {
        // Gọi hàm calculateBMI khi component được mounted
        this.calculateBMI()
    },
    methods: {
        calculateBMI() {
            const weight = parseFloat(this.weight)
            const height = parseFloat(this.height) / 100
            const bmi = weight / (height * height)
            this.bmi = isNaN(bmi) ? 0 : parseFloat(bmi.toFixed(2))
        },
        getCircleColorClass(bmi) {
            if (bmi >= 18.5 && bmi <= 24.9) {
                return 'circle-bg-blue'
            } else if (bmi >= 25.0 && bmi <= 39.9) {
                return 'circle-bg-yellow'
            } else if (bmi >= 40.0) {
                return 'circle-bg-red'
            } else {
                return ''
            }
        },
        getBMIStatus(bmi) {
            if (bmi && bmi < 18.5) {
                return 'Gầy'
            } else if (bmi && bmi >= 18.5 && bmi <= 24.9) {
                return 'Bình thường'
            } else if (bmi && bmi >= 25.0 && bmi <= 29.9) {
                return 'Hơi béo'
            } else if (bmi && bmi >= 30.0 && bmi <= 34.9) {
                return 'Béo phì cấp độ 1'
            } else if (bmi && bmi >= 35.0 && bmi <= 39.9) {
                return 'Béo phì cấp độ 2'
            } else if (bmi && bmi >= 40.0) {
                return 'Béo phì cấp độ 3 '
            } else {
                return ''
            }
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'weight',
                data: this.weight
            })
            this.$store.commit('updateSurveyData', {
                key: 'expectedWeight',
                data: 90
            })

            this.$store.commit('updateSurveyData', {
                key: 'height',
                data: this.height
            })
            this.$store.commit('updateSurveyData', {
                key: 'bmi',
                data: this.bmi
            })

            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step2 {
    /* height: 100vh; */
}

.img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
}
.form {
    margin-bottom: 20px;

    display: flex;
}
.now {
    margin-right: 50px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 900;
}

.form-kg {
    justify-content: flex-end;
}

.title-form {
    padding: 10px 0px 0px 10px;
    color: #0276fa;
    font-weight: 700;
    font-size: 22px;
}
.bmi {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.title-bmi {
    margin-right: 50px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
}
.input-bmi {
    height: 35px;
    width: 35%;
}
.img-bmi {
    width: 100%;
    object-fit: cover;
    margin-top: 30px;
}
::v-deep .el-input__inner {
    color: #0276fa;
    font-weight: 800;
    font-size: 20px;
    height: 40px;
}
::v-deep .el-input.is-disabled .el-input__inner {
    color: #0276fa;
    font-weight: 800;
    font-size: 20px;
    height: 40px;
}
.layout-btn {
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn {
    padding: 13px 120px 13px 120px;
    border-radius: 20px;
    text-transform: uppercase;
}
.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
}
.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
}
.bmi-container {
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.hr-bmi {
    height: 8px;

    border: none;

    border-radius: 8px;
    margin-bottom: 5px;
}
.hr-bmi-1 {
    background-color: #447cee;
    width: 60px;
}
.hr-bmi-2 {
    background-color: #6aa3fd;
    width: 80px;
}
.hr-bmi-3 {
    background-color: #4bd5ce;
    width: 100px;
}
.hr-bmi-4 {
    background-color: #ffe079;
    width: 80px;
}
.hr-bmi-5 {
    background-color: #ffc47a;
    width: 80px;
}
.hr-bmi-6 {
    background-color: #fc5a7a;
    width: 90px;
}
.value-bmi {
    font-size: 13px;
    color: #ffffff;
}
.text-value-bmi {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
}
.bmi-values {
    display: flex;
    justify-content: space-between;
}
.circle {
    width: 20px; /* Đặt chiều rộng */
    height: 20px; /* Đặt chiều cao */

    border-radius: 50%; /* Đặt border-radius thành 50% để tạo hình tròn */
    margin-right: 10px;
}
.circle-bg-blue {
    background-color: #0276fa;
}
.circle-bg-yellow {
    background-color: #ffc47a;
}
.circle-bg-red {
    background-color: #fc5a7a;
}

@media (max-width: 768px) {
    .btn {
        padding: 13px 50px 13px 50px;
    }
}
</style>

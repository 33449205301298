import Vue from 'vue'
import VueRouter from 'vue-router'
import { path } from './path'
// <!-- ************ Auth ************ -->
import SignUp from '@/views/SignUp/index.vue'
import PasswordGenerator from '@/views/SignUp/PasswordGenerator.vue'
import Success from '@/views/SignUp/Success.vue'
// ==============================|| USER ROUTING ||============================== //

// <!-- ************ Trang chủ ************ -->
import Home from '@/views/Home/index.vue'
import Detail from '@/views/Home/PlanIntro/Detail.vue'
// <!-- ************ Khảo sát ************ -->
import Survey from '@/views/Survey/index.vue'
import Analysis from '@/views/Survey/Step12.vue'
import PlanForYou from '@/views/Survey/Step13.vue'
import PlanDetail from '@/views/Survey/Step14.vue'
import PlanPackage from '@/views/Survey/ListPackage.vue'
import Login from '@/views/Login'
// <!-- ************ Thanh toán ************ -->
import Payment from '@/views/Pay/Payment.vue'
import ATM from '@/views/Pay/ATM.vue'
import QR from '@/views/Pay/QR.vue'

Vue.use(VueRouter)

const userRoutes = [
    // <!-- ************ Home ************ -->
    {
        path: path.home.home,
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home'
        }
    },
    {
        path: path.home.detail, // Sử dụng đường dẫn động ":id"
        name: 'Detail',
        component: Detail,
        props: true // Cho phép truyền tham số từ URL vào component
    },

    // <!-- ************ Khảo sát ************ -->
    {
        path: path.survey,
        name: 'Survey',
        component: Survey,
        meta: {
            title: 'Survey'
        }
    },
    {
        path: '/analysis',
        name: 'Analysis',
        component: Analysis,
        meta: {
            title: 'Analysis'
        }
    },
    {
        path: '/plan-for-you',
        name: 'PlanForYou',
        component: PlanForYou,
        meta: {
            title: 'PlanForYou'
        }
    },
    {
        path: '/plan-detail',
        name: 'PlanDetail',
        component: PlanDetail,
        meta: {
            title: 'PlanDetail'
        }
    },
    {
        path: '/plan-package',
        name: 'PlanPackage',
        component: PlanPackage,
        meta: {
            title: 'PlanPackage'
        }
    },

    // <!-- ************ Thanh toán ************ -->
    {
        path: path.pay.pay,
        redirect: { name: 'Payment' } // Chuyển hướng /pay sang /pay-payment
    },
    {
        path: path.pay.payment,
        name: 'Payment',
        component: Payment,
        meta: {
            title: 'Payment'
        }
    },
    {
        path: path.pay.atm,
        name: 'PayATM',
        component: ATM,
        meta: {
            title: 'Pay ATM'
        }
    },
    {
        path: path.pay.QR,
        name: 'PayQR',
        component: QR,
        meta: {
            title: 'Pay QR'
        }
    }
]

const routes = [
    // <!-- ************ Auth ************ -->
    {
        path: path.auth.login,
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: path.auth.signup,
        name: 'SignUp',
        component: SignUp,
        meta: {
            title: 'SignUp'
        }
    },
    {
        path: path.auth.password,
        name: 'PasswordGenerator',
        component: PasswordGenerator,
        meta: {
            title: 'PasswordGenerator'
        }
    },
    {
        path: path.auth.success,
        name: 'PasswordSuccess',
        component: Success,
        meta: {
            title: 'PasswordSuccess'
        }
    },
    ...userRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'VFitness'

    next()
})

export default router

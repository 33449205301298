import { render, staticRenderFns } from "./Step4.vue?vue&type=template&id=ca197134&scoped=true&"
import script from "./Step4.vue?vue&type=script&lang=js&"
export * from "./Step4.vue?vue&type=script&lang=js&"
import style0 from "./Step4.vue?vue&type=style&index=0&id=ca197134&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca197134",
  null
  
)

export default component.exports
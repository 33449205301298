<template>
    <div class="layout-selectbox">
        <div class="selectbox text" @click="nextValueSelectbox">
            {{ currentValueSelectbox.label }}
        </div>
        <div class="selectbox icon" @click="nextValueSelectbox">
            <i class="el-icon-d-caret"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomSelectBox',
    props: {
        list: {
            type: Array,
            required: true
        },
        value: {
            type: Number,
            required: true
        }
    },
    computed: {
        currentValueSelectbox() {
            return this.list.find((item) => item.id === this.value)
        }
    },
    methods: {
        nextValueSelectbox() {
            const currentIndex = this.list.findIndex(
                (item) => item.id === this.value
            )
            const nextIndex =
                currentIndex < this.list.length - 1 ? currentIndex + 1 : 0
            this.$emit('input', this.list[nextIndex].id)
        }
    }
}
</script>

<style scoped>
.layout-selectbox {
    display: flex;
    align-items: center;
}
.selectbox {
    cursor: pointer;
    color: #2e2b2e;
    font-weight: 700;
    background-color: #fff;
    background-image: none;

    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.text {
    border-radius: 8px 0px 0px 8px;
    width: 100%;
}
.icon {
    border-radius: 0px 8px 8px 0px;
}
</style>

<template>
    <div>
        <div class="video-free">
            <div class="container">
                <div class="sub-title">Chương trình luyện tập mới</div>
            </div>
            <div class="list-video">
                <!-- <div>
                    <img
                        src="@/assets/img/left-arrow.svg"
                        class="img-arrow"
                        @click="showNextListFree"
                    />
                </div> -->
                <div class="container slick">
                    <VueSlickCarousel v-bind="settings" ref="carouselListFree">
                        <div
                            v-for="item in listDataType1"
                            :key="item.id"
                            class="video"
                        >
                            <div>
                                <img :src="item.urlThumbnail" class="img" />
                            </div>
                            <div
                                class="icon-container"
                                v-if="item.locked === true"
                            >
                                <img src="@/assets/img/home4.svg" class="icon" />
                            </div>
                            <div
                                class="content"
                                v-if="item.locked === false"
                                @click="redirectToDetail(item)"
                            >
                                <div class="exercise-title">
                                    {{ item.title }}
                                </div>
                                <div class="exercise-description">
                                    {{ item.detail }}
                                </div>
                            </div>
                            <div
                                class="content bg-black"
                                v-else
                                @click="emitScrollToPay"
                            >
                                <div class="exercise-layout">
                                    <div class="exercise-title">Khóa</div>

                                    <div>
                                        <img
                                            src="@/assets/img/planIntro/img1.svg"
                                        />
                                    </div>
                                </div>
                                <div class="exercise-description">
                                    Đăng ký để mở khóa
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>
                <!-- <div>
                    <img
                        src="@/assets/img/right-arrow.svg"
                        class="img-arrow"
                        @click="showNextListFree"
                    />
                </div> -->
            </div>
        </div>
        <div class="video-pay">
            <div class="container">
                <div class="sub-title">Chương trình tập phổ biến</div>
            </div>
            <div class="list-video">
                <!-- <div>
                    <img
                        src="@/assets/img/left-arrow.svg"
                        class="img-arrow"
                        @click="showNextListPay"
                    />
                </div> -->
                <div class="container slick">
                    <VueSlickCarousel v-bind="settings" ref="carouselListPay">
                        <div
                            v-for="item in listDataType2"
                            :key="item.id"
                            class="video"
                        >
                            <div class="image-container">
                                <img :src="item.urlThumbnail" class="img" />
                            </div>
                            <div
                                class="icon-container"
                                v-if="item.locked === true"
                            >
                                <img src="@/assets/img/home4.svg" class="icon" />
                            </div>
                            <div
                                class="content"
                                v-if="item.locked === false"
                                @click="redirectToDetail(item)"
                            >
                                <div class="exercise-title">
                                    {{ item.title }}
                                </div>
                                <div class="exercise-description">
                                    {{ item.detail }}
                                </div>
                            </div>
                            <div class="content bg-black" v-else>
                                <div class="exercise-layout">
                                    <div class="exercise-title">Khóa</div>

                                    <div>
                                        <img
                                            src="@/assets/img/planIntro/img1.svg"
                                        />
                                    </div>
                                </div>
                                <div class="exercise-description">
                                    Đăng ký để mở khóa
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>
                <!-- <div>
                    <img
                        src="@/assets/img/right-arrow.svg"
                        class="img-arrow"
                        @click="showNextListPay"
                    />
                </div> -->
            </div>
        </div>
        <div class="video-girl">
            <div class="container">
                <div class="sub-title">Lựa chọn tốt khi tập ở nhà</div>
            </div>
            <div class="list-video">
                <!-- <div>
                    <img
                        src="@/assets/img/left-arrow.svg"
                        class="img-arrow"
                        @click="showNextListGirl"
                    />
                </div> -->
                <div class="container slick">
                    <VueSlickCarousel v-bind="settings" ref="carouselListGirl">
                        <div
                            v-for="item in listDataType3"
                            :key="item.id"
                            class="video"
                        >
                            <div class="image-container">
                                <img :src="item.urlThumbnail" class="img" />
                            </div>
                            <div
                                class="icon-container"
                                v-if="item.locked === true"
                            >
                                <img src="@/assets/img/home4.svg" class="icon" />
                            </div>
                            <div
                                class="content"
                                v-if="item.locked === false"
                                @click="redirectToDetail(item)"
                            >
                                <div class="exercise-title">
                                    {{ item.title }}
                                </div>
                                <div class="exercise-description">
                                    {{ item.detail }}
                                </div>
                            </div>
                            <div
                                class="content bg-black"
                                v-else
                                @click="emitScrollToPay"
                            >
                                <div class="exercise-layout">
                                    <div class="exercise-title">Khóa</div>

                                    <div>
                                        <img
                                            src="@/assets/img/planIntro/img1.svg"
                                        />
                                    </div>
                                </div>
                                <div class="exercise-description">
                                    Đăng ký để mở khóa
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>
                <!-- <div>
                    <img
                        src="@/assets/img/right-arrow.svg"
                        class="img-arrow"
                        @click="showNextListGirl"
                    />
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Api from '@/constants/Api'
import sendRequest from '@/services'
import { filterItemsByType } from '@/utils/common'
import { saveToSessionStorage } from '@/utils/session'
export default {
    name: 'ListData',
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: true,
                arrows: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },
            listDataType1: [
                {
                    id: 1,

                    title: 'Bài Tập Bụng',
                    description: 'Plank, Gập Bụng, ...'
                },
                {
                    id: 2,

                    title: 'Bài Tập Ngực',
                    description: 'Đẩy tạ, Bench Press,  ...'
                },
                {
                    id: 3,

                    title: 'Bài Tập Lưng',
                    description: 'Lên xà, Kéo dây, ...'
                },
                {
                    id: 4,

                    title: 'Bài Tập Bụng 1',
                    description: 'Plank, Gập Bụng, ...'
                }
            ],
            listDataType2: [
                {
                    id: 1,

                    title: 'Bài Tập Bụng',
                    description: 'Plank, Gập Bụng, ...'
                },
                {
                    id: 2,

                    title: 'Bài Tập Ngực',
                    description: 'Đẩy tạ, Bench Press,  ...'
                },
                {
                    id: 3,

                    title: 'Bài Tập Lưng',
                    description: 'Lên xà, Kéo dây, ...'
                },
                {
                    id: 4,

                    title: 'Bài Tập Bụng 1',
                    description: 'Plank, Gập Bụng, ...'
                }
            ],
            listDataType3: [
                {
                    id: 1,

                    title: 'Bài Tập Bụng',
                    description: 'Plank, Gập Bụng, ...'
                },
                {
                    id: 2,

                    title: 'Bài Tập Ngực',
                    description: 'Đẩy tạ, Bench Press,  ...'
                },
                {
                    id: 3,

                    title: 'Bài Tập Lưng',
                    description: 'Lên xà, Kéo dây, ...'
                },
                {
                    id: 4,

                    title: 'Bài Tập Bụng 1',
                    description: 'Plank, Gập Bụng, ...'
                }
            ]
        }
    },
    methods: {
        emitScrollToPay() {
            this.$emit('scrollToPay')
        },
        showNextListFree() {
            this.$refs.carouselListFree.next()
        },
        showNextListPay() {
            this.$refs.carouselListPay.next()
        },
        showNextListGirl() {
            this.$refs.carouselListGirl.next()
        },
        redirectToDetail(item) {
            this.$router.push({ name: 'Detail', params: { id: item.id } })
            saveToSessionStorage('detail', item)
        },
        async getListData() {
            try {
                const request_Params = {
                    page: 1,
                    size: 10,
                    status: 1
                }
                const response = await sendRequest(
                    Api.planIntro.getListData,
                    null,
                    null,
                    request_Params
                )

                const arrayDataType = response.item.content
                this.listDataType1 = filterItemsByType(arrayDataType, 1)

                this.listDataType2 = filterItemsByType(arrayDataType, 2)
                this.listDataType3 = filterItemsByType(arrayDataType, 3)
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        this.getListData()
    }
}
</script>

<style scoped>
.list-video {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-pay,
.video-girl {
    padding-top: 60px;
}
.sub-title {
    padding-top: 20px;
    padding-bottom: 10px;
    color: #191718;
    font-size: 24px;
    font-weight: 800;
}
.slick {
    margin: 0px 30px 0px 30px;
}
.navigate-detail {
    cursor: pointer;
}
.video {
    padding: 0px 5px 0px 5px;
}
.content {
    cursor: pointer;
    color: #ffffff;
    background-color: #0276fa;
    height: 100px;
    padding: 20px 10px 20px 10px;
    border-radius: 0px 0px 24px 24px;
}
.bg-black {
    background-color: #191718 !important;
}
.image-container,
.icon-container {
    position: relative;
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    image-rendering: pixelated;
    border-radius: 24px 24px 0px 0px;
}

.icon {
    width: 100%;
    height: 200px;
    object-fit: cover;
    image-rendering: pixelated;
    border-radius: 24px 24px 0px 0px;
    position: absolute;
    top: -200px;
    left: 0;
}
.exercise-layout {
    display: flex;
    align-items: center;
}
.exercise-title {
    padding: 0px 10px 0px 0px;

    font-size: 16px;
    font-weight: 800;
}
.exercise-description {
    margin-top: 5px;

    font-size: 14px;
    font-weight: 700;
}
.exercise-description-girl {
    margin-top: 5px;

    font-size: 14px;
    font-weight: 700;
}
.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #0276fa;
    border-radius: 50%;
    margin-right: 5px;
}
.img-arrow {
    cursor: pointer;
    height: 40px;
    object-fit: cover;
}
.pointer-cursor {
    cursor: pointer;
}
::v-deep .slick-dots li.slick-active button:before {
    margin-top: 10px;
    color: #0276fa;
}
::v-deep .slick-dots li button:before {
    margin-top: 10px;
    font-size: 14px;

    color: #191718;
}
</style>

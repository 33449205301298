<template>
    <div class="payment">
        <div class="container">
            <div class="title">Thanh toán</div>
            <div class="hr">
                <hr />
            </div>
            <div class="body">
                <div class="content">Phương thức thanh toán</div>
                <div class="radios">
                    <el-radio-group v-model="radio">
                        <el-radio
                            :label="option.label"
                            v-for="option in radioOptions"
                            :key="option.label"
                        >
                            <img :src="option.imgSrc" class="img" />
                            {{ option.text }}
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="layout-btn">
<!--                <CustomButton-->
<!--                    label="Quay lại"-->
<!--                    size="medium"-->
<!--                    type="text"-->
<!--                    class="btn-white custom-btn"-->
<!--                />-->

                <CustomButton
                    label="Thanh toán"
                    size="medium"
                    type="text"
                    class="btn-red custom-btn"
                    @click="handleNavigate"
                />
            </div>
        </div>
    </div>
</template>
<script>
import payment1 from '@/assets/img/payment1.svg'
import payment2 from '@/assets/img/payment2.svg'
import payment3 from '@/assets/img/payment3.svg'
import CustomButton from '@/components/CustomButton.vue'
import { path } from '@/router/path'
export default {
    name: 'PayMent',
    components: { CustomButton },
    data() {
        return {
            radioOptions: [
                // {
                //     label: 3,
                //     imgSrc: payment1,
                //     text: 'Thanh toán bằng thẻ ATM'
                // },
                // {
                //     label: 6,
                //     imgSrc: payment2,
                //     text: 'Thẻ tín dụng/ghi nợ quốc tế (Visa, Mastercard, JCB)'
                // },
                {
                    label: 9,
                    imgSrc: payment3,
                    text: 'Chuyển khoản ngân hàng'
                }
            ],
            radio: 9
        }
    },
    methods: {
        handleNavigate() {
            // if (this.radio === 3) {
            //     this.$router.push(path.pay.atm)
            // }
            if (this.radio == 9){
                this.$router.push(path.pay.QR)
            }
        }
    }
}
</script>

<style scoped>
.payment {
    margin-top: 20px;
}
.title {
    font-weight: 900;
    color: #0276fa;
    font-size: 36px;
}
.hr {
    margin-top: 30px;
}
.hr hr {
    margin: 0;
    border: 0.1px solid #f5f7f78f;
    width: 75%;
}
.content {
    padding: 30px 0px 50px 0px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800;
}

.radio {
    margin-bottom: 35px;
}
.img {
    margin: -10px 30px 0;
    padding: 0px;
}
.btn-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.custom-btn {
    padding: 13px 70px;
    border-radius: 20px;
}
.layout-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #0276fa;
    background: #0276fa;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #ffffff;
}
::v-deep .el-radio-group {
    flex-direction: column;
    display: flex;
}
::v-deep .el-radio {
    margin-bottom: 60px;
}
</style>

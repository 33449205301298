<template>
    <div class="step5">
        <TitleSurvey :title="'Mục tiêu tập luyện'" />
        <div class="container">
            <div
                class="row align-items-center justify-content-center text-center mt-5"
            >
                <div class="col-md-6">
                    <div class="d-flex flex-column">
                        <div
                            v-for="(item, index) in list_target"
                            :key="index"
                            class="flex-fill mt-2 list btn-white"
                            :class="{
                                selectedTarget: target === item.id
                            }"
                            @click="handleTargetClick(item.id)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                :disabled="target === null"
                label="Tiếp tục"
                size="medium"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'

import { mapState } from 'vuex'
export default {
    name: 'StepFive',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            list_target: [],
            target: null
        }
    },

    mounted() {
        this.getListTarget()
    },
    computed: {
        ...mapState({
            currentBodyType: (state) => state.surveyData.currentBodyType
        })
    },
    methods: {
        async getListTarget() {
            try {
                const response = await sendRequest(Api.survey.getListTarget)
                this.list_target = response.targets

                switch (this.currentBodyType) {
                    case 0:
                    case 1:
                        this.list_target = response.targets.filter(
                            (item) => item.id !== 2
                        )
                        break

                    case 2:
                        this.list_target = response.targets.filter(
                            (item) => item.id === 5
                        )
                        break

                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        this.list_target = response.targets.filter(
                            (item) => item.id !== 1
                        )
                        break

                    default:
                        this.list_target = response.targets || []
                        break
                }
            } catch (error) {
                console.error('Error fetching list of targets:', error)
                // Xử lý lỗi nếu cần
            }
        },
        handleTargetClick(id) {
            this.target = id
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'target',
                data: this.target
            })
            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step5 {
    height: 80vh;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 18px;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;

    padding: 13px 0;
}
.list:hover {
    background-color: #ccdbff;
    cursor: pointer;
}
.selectedTarget {
    background-color: #0276fa !important;
    color: #ffffff !important;
}
.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 12px;
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px;
        border-radius: 20px;
    }
}
</style>

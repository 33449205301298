<template>
    <div class="payment" id="pay-section">
        <TitleHome sub="Bảng giá" />
        <div class="row layout-data align-items-center">
            <div
                class="col-md-4 mb-5"
                v-for="(item, index) in items"
                :key="index"
            >
                <div class="data">
                    <div class="header">
                        <div class="header-title">{{ item.title }}</div>
                        <img
                            v-if="item.hasCorner"
                            src="@/assets/img/payment10.svg"
                            class="corner"
                        />
                    </div>
                    <div>
                        <div class="price">
                            {{ item.price }} <span>/1 tháng</span>
                        </div>
                        <div
                            class="note"
                            :class="{
                                'no-padding': item.points.length === 0
                            }"
                        >
                            <li
                                v-for="(point, pointIndex) in item.points"
                                :key="pointIndex"
                            >
                                {{ point }}
                            </li>
                        </div>
                    </div>
                    <div
                        class="layout-btn"
                        :class="{ 'no-padding': !item.showButton }"
                    >
                        <CustomButton
                            label="Đăng ký"
                            size="medium"
                            type="text"
                            class="btn-red"
                            v-if="item.showButton"
                            @click="handleNavigatePay"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import TitleHome from '@/components/TitleHome.vue'
import { path } from '@/router/path'

export default {
    name: 'PayMent',
    components: { CustomButton, TitleHome },
    data() {
        return {
            items: [
                {
                    title: 'Cơ bản',
                    price: '499k',
                    points: [
                        'Truy cập không giới hạn vào bài tập và chương trình tập luyện',
                        'Thư viện video và hình ảnh hướng dẫn',
                        'Nội dung của gói'
                    ],
                    showButton: true
                },
                {
                    title: 'Tiêu chuẩn',
                    price: '699k',
                    points: []
                },
                {
                    title: 'Cao cấp',
                    price: '999k',
                    points: [],
                    hasCorner: true
                }
            ]
        }
    },
    methods: {
        handleNavigatePay() {
            this.$router.push(path.pay.payment)
        }
    }
}
</script>
<style scoped>
.payment {
    padding-top: 50px;
    padding-bottom: 50px;
}

.layout-data {
    margin-top: 30px;
}
.data {
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.header {
    border-radius: 8px 8px 0px 0px;

    background-color: #0276fa;
    padding: 20px;
    position: relative;
}
.header-title {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}
.corner {
    position: absolute;
    top: -60%;
    right: -20%;
}

.price {
    text-align: center;
    font-size: 50px;
    color: #191718;
    font-weight: 900;
}
.price span {
    font-size: 20px;
    margin-left: 10px;
}
.note {
    list-style-type: disc;

    padding: 10px 50px 10px 50px;
}
.note li {
    font-size: 15px;
    font-weight: 700;
    color: #191718;
    margin-bottom: 15px;
}
.layout-btn {
    padding: 0 20px 20px 20px;
    display: flex;
    justify-content: center;
}
.btn-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
    padding: 15px 70px;
    border-radius: 10px;
}
.no-padding {
    padding: 0;
}
@media (max-width: 767px) {
    .data {
        margin-left: 20px;
        margin-right: 20px;
    }
    .layout-data {
        margin-top: 10px;
    }
    .col-md-4 {
        margin-bottom: 1.5rem;
    }
    .title {
        margin-left: 20px;
        margin-right: 20px;
    }
    .payment {
        padding-top: 70px;
        padding-bottom: 30px;
    }
    .corner {
        top: -30%;
        right: -5%;
        height: 100%;
    }
}
</style>

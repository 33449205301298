<template>
    <div class="step9">
        <TitleSurvey :title="'Nơi tập luyện'" />
        <div class="container">
            <div class="row justify-content-center text-center">
                <div
                    class="col-md-5 d-flex align-items-center justify-content-center text-center"
                >
                    <div
                        v-for="(step, index) in list_practicePlace"
                        :key="index"
                        class="layout-img"
                        @click="handlePracticePlaceClick(step.id)"
                    >
                        <div
                            :class="{
                                practicePlace: practicePlace === step.id
                            }"
                            class="overlay-container"
                        >
                            <div
                                v-if="practicePlace === step.id"
                                class="overlay"
                            ></div>
                            <img :src="step.imgSrc" class="img" />
                        </div>
                        <div class="title-img">{{ step.title }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Tiếp tục"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="updateInfo"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import img1 from '@/assets/img/step9/img1.svg'
import img2 from '@/assets/img/step9/img2.svg'
import img3 from '@/assets/img/step9/img3.svg'
import TitleSurvey from '@/components/TitleSurvey.vue'
import sendRequest from "@/services";
import Api from "@/constants/Api";
import {getFromLocalStorage} from "@/utils/localStorage";
import {getFromSessionStorage} from "@/utils/session";

export default {
    name: 'StepNine',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            surveyData: {},

            practicePlace: 2,

            list_practicePlace: [
                { imgSrc: img2, title: 'Tại nhà', id: 1 },
                { imgSrc: img1, title: 'Phòng tập', id: 2 }
            ]
        }
    },
    methods: {

        async updateInfo() {


            let user = getFromSessionStorage('user')

            this.$store.commit('updateSurveyData', {
                key: 'birthDay',
                data: user.userInfoIdentifing.dateOfBirth
            })
            this.$store.commit('updateSurveyData', {
                key: 'hoTen',
                data: user.userInfoIdentifing.displayName
            })
            this.$store.commit('updateSurveyData', {
                key: 'phoneNumber',
                data: user.userInfoIdentifing.phoneNumber
            })
            this.$store.commit('updateSurveyData', {
                key: 'email',
                data: user.userInfoIdentifing.email
            })
            this.$store.commit('updateSurveyData', {
                key: 'gender',
                data: user.userInfoIdentifing.gender
            })

            this.surveyData = getFromLocalStorage('surveyData')

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            let token = user.token
            let item = { item: { ...this.surveyData, isEquipment: 0,practicePlace   : this.practicePlace } }
            try {
                const response = await sendRequest(
                    Api.survey.createSurvey,
                    item,
                    {
                        token: token
                    }
                )
                if (response.code === 200) {
                    loading.close()

                    this.$emit('handleNextStep')
                } else {
                    this.dialogVisible = true
                    this.messageResponse = response.message
                    loading.close()
                }
            } catch (error) {
                loading.close()
                console.log(error)
            }
        },


        handlePracticePlaceClick(id) {
            this.practicePlace = id
        },

        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'practicePlace',
                data: this.practicePlace
            })

            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step9 {
    height: 90vh;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 12px;
}
.layout-img {
    padding: 0px 0px 0px 10px;
    cursor: pointer;
}
.title-img {
    margin-top: 5px;
    color: #ffffff;
    font-weight: 700;
}
.img {
    object-fit: cover;
    height: 400px;
}

.equipment {
    background-color: #0276fa !important;
    color: #ffffff !important;
}
.isEquipment {
    background-color: #0276fa !important;
    color: #ffffff !important;
}

.data {
    cursor: pointer;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 15px 5px;
}
.data:hover {
    background-color: #ccdbff;
    cursor: pointer;
}
.overlay-container {
    position: relative;
}

.overlay {
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
        0,
        0,
        0,
        0.5
    ); /* Điều chỉnh màu và độ mờ theo ý muốn */
    z-index: 1; /* Đảm bảo overlay nằm trên ảnh */
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px;
        border-radius: 20px;
    }
}
</style>

export const saveToLocalStorage = (data, key) => {
    try {
        // Chuyển đối tượng thành chuỗi JSON trước khi lưu
        const json = JSON.stringify(data)
        localStorage.setItem(key, json)
    } catch (error) {
        // Xử lý lỗi nếu có
        console.error('Lỗi khi lưu vào local storage:', error)
    }
}

export const getFromLocalStorage = (key) => {
    try {
        // Lấy dữ liệu từ local storage bằng key
        const json = localStorage.getItem(key)

        // Kiểm tra nếu dữ liệu tồn tại
        if (json !== null && json !== undefined) {
            // Parse chuỗi JSON thành đối tượng hoặc mảng
            return JSON.parse(json)
        }

        // Trả về null nếu không tìm thấy dữ liệu
        return null
    } catch (error) {
        // Xử lý lỗi nếu có
        console.error('Lỗi khi lấy dữ liệu từ local storage:', error)
        return null
    }
}

<template>
    <div class="step8">
        <TitleSurvey :title="'Thời gian tập luyện'" />
        <div class="container">
            <div class="sub-title">Lựa chọn số buổi tập trong tuần</div>
            <div class="row mt-4 align-items-center justify-content-center mt-5">
                <div
                    v-for="(item, index) in list_option_days_per_week"
                    :key="index"
                    class="btn-white option_days_per_week m-2"
                    :class="{
                        selectedPptionDaysPerWeek: optionDaysPerWeek === item.id
                    }"
                    @click="handleOptionDaysPerWeekClick(item.id)"
                >
                    {{ item.label }}
                </div>
            </div>
            <div class="sub-title mt-5">Thời gian tập luyện</div>
            <div class="row align-items-center justify-content-center mt-4">
                <div class="col-sm-4">
                    <CustomSelectBox v-model="planTime" :list="list" />
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn custom-btn-2 btn-white"
                @click="BackStep"
            />
            <CustomButton
                :disabled="optionDaysPerWeek === null"
                label="Tiếp tục"
                size="medium"
                class="btn custom-btn-2 btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomSelectBox from '@/components/CustomSelectBox.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'
import Api from '@/constants/Api'
import { getFromSessionStorage } from '@/utils/session'
import sendRequest from '@/services'

export default {
    name: 'StepEight',
    components: { CustomButton, CustomSelectBox, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            practice_weekdays: 3,
            marks: {
                3: '3',
                4: '4',
                5: '5'
            },
            list: [
                {
                    label: '2 Tuần',
                    id: 1
                },
                {
                    label: '4 Tuần',
                    id: 2
                },
                {
                    label: '8 Tuần',
                    id: 3
                },
                {
                    label: '12 Tuần ',
                    id: 4
                }
            ],
            planTime: 2,
            list_option_days_per_week: [
                { id: 1, label: '3-4 buổi trên tuần' },
                { id: 2, label: '3-4-5 buổi trên tuần' },
                { id: 3, label: '4-5 buổi trên tuần' }
            ],
            optionDaysPerWeek: null
        }
    },

    methods: {
        handleOptionDaysPerWeekClick(id) {
            this.optionDaysPerWeek = id
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            // this.$store.commit('updateSurveyData', {
            //     key: 'practice_weekdays',
            //     data: this.practice_weekdays
            // })
            this.$store.commit('updateSurveyData', {
                key: 'optionDaysPerWeek',
                data: this.optionDaysPerWeek
            })

            this.$store.commit('updateSurveyData', {
                key: 'planTime',
                data: this.planTime
            })

            this.$emit('handleNextStep')
        },
        async getListTime() {
            try {

                let user = getFromSessionStorage('user')
                let token = user.token
                const request_Params = {
                    page: 1,
                    size: 1,
                    id: 's_plan_time'
                }
                const request_Header = {
                    token: token
                }
                console.log("token ne")
                console.log(token)
                const response = await sendRequest(
                    Api.survey.listPlanTime,
                    null,
                    request_Header,
                    request_Params
                )
                if(response.code===200){
                    console.log('get time ne')
                    console.log(response)
                    // this.list_option_days_per_week= response.item.content[0].config.days_per_week.options;
                }
            } catch (e) {
                console.log(e)
            }
        }
    },
    mounted() {
        // this.getListTime()
    }
}
</script>

<style scoped>
.step8 {
    height: 80vh;
}

.sub-title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.btn {
    border-radius: 20px;
    text-transform: uppercase;
}

.custom-btn {
    padding: 15px 15px;
}

.custom-btn-2 {
    padding: 13px 120px;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
}

::v-deep .el-slider__runway {
    background-color: #0276fa;
    height: 15px;
}

::v-deep .el-slider__button {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    border: #ffffff;
}

::v-deep .el-slider__bar {
    height: 15px;
    background-color: #c4c4c4;
}

::v-deep .el-slider__stop {
    display: none;
}

.list {
    white-space: nowrap;
    margin: 5px;
    font-weight: 600;
    border-radius: 20px;
    text-transform: uppercase;
    padding: 10px;
}

.form {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.time {
    color: #ffffff;
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
}

.option_days_per_week {
    border-radius: 16px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    color: #2e2b2e;
    font-weight: 600;
}

.selectedPptionDaysPerWeek {
    background-color: #0276fa !important;
    color: #ffffff !important;
}

@media (max-width: 768px) {
    .btn {
        padding: 13px 50px 13px 50px;
    }

    .col-md-4.text {
        text-align: center; /* Căn giữa nội dung trong cột đầu tiên trên mobile */
        margin-bottom: 10px; /* Tạo khoảng cách dưới cột đầu tiên */
    }

    .col-md-7.pl-0 {
        padding-left: 0; /* Loại bỏ padding trái cho cột thứ hai trên mobile */
    }

    /* Tùy chỉnh CSS cho các nút trên mobile ở đây */
    .btn.custom-btn {
        width: 100%; /* Độ rộng 100% để nút tràn đầy chiều rộng của cột */
        margin-bottom: 10px; /* Tạo khoảng cách giữa các nút */
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
}
</style>

<template>
    <div class="step11">
        <TitleSurvey :title="'Thông tin cá nhân'" />
        <div></div>
        <div class="container">
            <div class="row justify-content-center text-center">
                <div
                    class="col-md-6 mt-2 align-items-center justify-content-center"
                >
                    <div class="form">
                        <div class="label">
                            Họ và tên <span class="error">*</span>
                        </div>
                        <div class="input">
                            <CustomInput
                                v-model="hoTen"
                                placeholder="Họ và tên"
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                            <div v-if="error_hoTen.length > 0" class="error">
                                {{ error_hoTen }}
                            </div>
                        </div>
                    </div>
                    <div class="form row">
                        <div class="label col-sm-12">
                            Ngày sinh <span class="error">*</span>
                        </div>
                        <div class="input col-sm-12">
                            <el-date-picker
                                style="width: 100%"
                                v-model="birthDay"
                                type="date"
                                placeholder="Chọn"
                                format="dd/MM/yyyy"
                                size="large"
                            >
                            </el-date-picker>
                            <div v-if="error_birthDay.length > 0" class="error">
                                {{ error_birthDay }}
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="label">
                            Số điện thoại <span class="error">*</span>
                        </div>
                        <div class="input">
                            <CustomInput
                                v-model="phoneNumber"
                                placeholder="12345678910JQK"
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                            <div
                                v-if="error_phoneNumber.length > 0"
                                class="error"
                            >
                                {{ error_phoneNumber }}
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="label">Email</div>
                        <div class="input">
                            <CustomInput
                                v-model="email"
                                placeholder="email@gmail.com"
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                            <div v-if="error_email.length > 0" class="error">
                                {{ error_email }}
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <div class="note">
                            Lưu ý:
                            <span>
                                Số điện thoại này dùng để đăng ký tài khoản. Vui
                                lòng nhập chính xác số điện thoại của bạn
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-3 mb-5">
            <div class="row justify-content-center align-items-center">
                <CustomButton
                    label="Quay lại"
                    size="medium"
                    type="text"
                    class="btn btn-white custom-btn"
                    @click="BackStep"
                />
                <CustomButton
                    label="Xem phân tích"
                    size="medium"
                    type="text"
                    class="btn btn-blue custom-btn"
                    @click="NextStep"
                />
            </div>
        </div>
        <ElDialog
            :title="'Thông báo'"
            :message="messageResponse"
            :visible.sync="dialogVisible"
            :textBtn="'Thực hiện lại'"
            :showButton="false"
        />
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { getFromLocalStorage } from '@/utils/localStorage'
import { validateField } from '@/utils/validateInput'
import moment from 'moment'
import { mapState } from 'vuex'
import TitleSurvey from '@/components/TitleSurvey.vue'
import ElDialog from '@/components/ElDialog.vue'
export default {
    name: 'StepEleven',
    components: { CustomButton, CustomInput, TitleSurvey, ElDialog },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            dialogVisible: false,

            hoTen: '',
            birthDay: '',
            phoneNumber: '',
            email: '',
            error_hoTen: '',
            error_birthDay: '',
            error_phoneNumber: '',
            error_email: '',
            response: null,
            messageResponse: ''
        }
    },
    computed: {
        getCurrentBodyInfo() {
            return this.list_body_type[this.currentBodyType / 10]
        },
        ...mapState({
            surveyData: (state) => state.surveyData
        })
    },
    methods: {
        BackStep() {
            this.$emit('handleBackStep')
        },
        async NextStep() {
            const fieldsToValidate = [
                {
                    name: 'hoTen',
                    value: this.hoTen,
                    errorField: 'error_hoTen'
                },
                {
                    name: 'birthDay',
                    value: this.birthDay,
                    errorField: 'error_birthDay'
                },
                {
                    name: 'phoneNumber',
                    value: this.phoneNumber,
                    errorField: 'error_phoneNumber'
                },
                {
                    name: 'email',
                    value: this.email,
                    errorField: 'error_email'
                }
            ]
            let isValid = true

            for (const field of fieldsToValidate) {
                isValid = validateField(this, field, isValid)
            }

            if (isValid) {
                const birthDayTimestamp = moment(this.birthDay).valueOf()
                this.$store.commit('updateSurveyData', {
                    key: 'birthDay',
                    data: birthDayTimestamp
                })
                this.$store.commit('updateSurveyData', {
                    key: 'hoTen',
                    data: this.hoTen
                })
                this.$store.commit('updateSurveyData', {
                    key: 'phoneNumber',
                    data: this.phoneNumber
                })
                this.$store.commit('updateSurveyData', {
                    key: 'email',
                    data: this.email
                })

                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })

                let item = { item: { ...this.surveyData, isEquipment: 0 } }
                try {
                    const response = await sendRequest(
                        Api.survey.createSurvey,
                        item
                    )
                    if (response.code === 200) {
                        loading.close()

                        this.$router.push('/analysis')
                    } else {
                        this.dialogVisible = true
                        this.messageResponse = response.message
                        loading.close()
                    }
                } catch (error) {
                    loading.close()
                    console.log(error)
                }
            }
        }
    },
    created() {
        const survey = getFromLocalStorage('surveyData')

        this.hoTen = survey.hoTen
        this.birthDay = survey.birthDay
        this.phoneNumber = survey.phoneNumber
        this.email = survey.email
    }
}
</script>

<style scoped>
.step11 {
    height: 90vh;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.custom-btn {
    padding: 15px 130px;
    margin-right: 20px;
}

.form {
    margin-bottom: 20px;
}
.label {
    color: #ffffff;
    font-weight: 700;
    text-align: left;
}
.input {
    margin-top: 10px;
}
.error {
    color: red;
    font-weight: 600;
    text-align: left;
    margin-top: 5px;
    font-style: italic;
}
.note {
    text-align: left;
    color: red;
    font-weight: 600;
    font-size: 16px;
}
.note span {
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
}
::v-deep .el-input__inner {
    height: 40px;
    border-radius: 8px;
    color: #2e2b2e;
}
::v-deep .el-dialog {
    border-radius: 12px;
}
::v-deep .el-dialog__header {
    border-radius: 12px 12px 0px 0px;
    background-color: #014eff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
::v-deep .el-dialog__title {
    color: #ffffff;
    font-size: 22px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    font-size: 22px;
    font-weight: 900;
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px;
        border-radius: 20px;
    }
}
</style>

<template>
    <div class="step6">
        <TitleSurvey :title="'Bệnh lý và vấn đề liên quan'" />
        <div class="container">
            <div
                class="row align-items-center justify-content-center text-center mt-5"
            >
                <div class="col-md-6">
                    <div class="d-flex flex-column">
                        <div
                            v-for="(item, index) in list_sick"
                            :key="index"
                            class="flex-fill mt-2 list btn-white"
                            :class="{
                                sick: sick.includes(item.id)
                            }"
                            @click="handleSickClick(item.id)"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                :disabled="sick.length === 0"
                label="Tiếp tục"
                size="medium"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'

export default {
    name: 'StepSix',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            list_sick: [],
            sick: [1]
        }
    },
    methods: {
        handleSickClick(id) {
            const index = this.sick.indexOf(id)
            if (index === -1) {
                this.sick.push(id)
            } else {
                this.sick.splice(index, 1)
            }
            console.log(this.sick)
        },
        async getListSick() {
            try {
                const response = await sendRequest(Api.survey.getLickSick)
                this.list_sick = response.item.content
            } catch (error) {
                console.log(error)
            }
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'sick',
                data: this.sick
            })
            this.$emit('handleNextStep')
        }
    },
    mounted() {
        this.getListSick()
    }
}
</script>

<style scoped>
.step6 {
    height: 80vh;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 18px;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 20px;

    padding: 13px 0;
}
.list:hover {
    background-color: #ccdbff;
    cursor: pointer;
}
.sick {
    background-color: #0276fa !important;
    color: #ffffff !important;
}
.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 12px;
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px;
        border-radius: 20px;
    }
}
</style>

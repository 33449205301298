const pay = '/pay'

export const path = {
    auth: {
        login: '/login', // Đăng nhập
        signup: '/sign-up', // Đăng ký
        password: '/sign-up/password', // Tạo mật khẩu
        success: '/sign-up/success' // Đăng ký thành công
    },
    home: {
        home: '/', // Trang chủ
        detail: '/detail/:id'
    },

    survey: '/survey', // Khảo sát
    pay: {
        pay: pay, // Router cha // Thanh toán
        payment: `/${pay}-payment`,
        atm: `/${pay}-atm`,
        QR: `/${pay}-QR`
    }
}

<template>
    <el-dialog
        :title="title"
        :visible="visible"
        @close="closeDialog"
        width="30%"
        center
    >
        <span class="body">
            {{ message }}
        </span>
        <span slot="footer" class="dialog-footer">
            <div class="row align-items-center justify-content-center">
                <div class="col-sm-4">
                    <el-button
                        class="btn-dialog btn-close-dialog"
                        @click="closeDialog"
                    >{{ textBtnClose }}</el-button
                    >
                </div>
                <div class="col-sm-2"></div>
                <div class="col-sm-4 ml-3" v-if="showButton">
                    <el-button
                        class="btn-dialog btn-click-dialog"
                        @click="confirmAction"
                    >
                        {{ textBtn }}
                    </el-button>
                </div>
            </div>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: String,
        message: String,
        visible: Boolean,
        textBtn: String,
        textBtnClose: {
            type: String,
            default: 'Đóng'
        },
        nextStep: {
            type: Boolean,
            default: false
        },
        showButton: Boolean
    },
    methods: {
        closeDialog() {
            this.$emit('update:visible', false)
            if (this.nextStep) {
                this.$emit('nextStep')
            }
        },
        confirmAction() {
            // Xử lý hành động khi nút "Thực hiện lại" được nhấn
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped>
::v-deep .el-dialog {
    border-radius: 12px;
}

.body {
    text-align: center;
    color: black;
    font-size: 16px;
}

::v-deep .el-dialog__header {
    padding: 20px 10px 20px 10px;
    border-radius: 12px 12px 0px 0px;
    background-color: #014eff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

::v-deep .el-dialog__title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 900;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    font-size: 22px;
    font-weight: 900;
}

.btn-close-dialog {
    background-color: #e8e8e8;
    color: black;
    padding: 10px 50px 10px 50px;
}

.btn-click-dialog {
    background-color: #014cff;
    color: #ffffff;
    padding: 10px 30px 10px 30px;
}

.btn-dialog {
    border-radius: 8px;
}
</style>

<template>
    <header class="header" :class="{ 'fixed-header': isFixedHeader }">
        <div class="header-layout">
            <div class="header-left">
                <div class="header-logo">
                    <img
                        src="@/assets/img/Asset 1 1.png"
                        class="img"
                        @click="handleNavigateHome"
                    />
                    <img
                        src="@/assets/img/VFITNESS.svg"
                        class="img"
                        @click="handleNavigateHome"
                    />
                </div>
            </div>
            <div class="header-right">
<!--                <div class="download" @click="handleNavigateSignUp">-->
<!--                    Tải xuống-->
<!--                </div>-->
                <div class="download" v-if="name">
                    Xin chào, {{name}}!
                </div>
                <div class="download" @click="handleNavigateSLogout" v-if="isLogin">
                    Đăng xuất
                </div>
                <div class="download" @click="handleNavigateSLogin" v-if="!isLogin">
                    Đăng nhập
                </div>
                <div class="language" @click="handleNavigateSignUp" v-if="!isLogin">Đăng ký</div>
            </div>
        </div>
    </header>
</template>
<script>
import { path } from '@/router/path'
import { mapState } from 'vuex'
import { deleteSessionStorage, getFromSessionStorage, saveToSessionStorage } from '@/utils/session'
export default {
    name: 'HeaderComponent',
    components: {},

    mounted() {
        this.isLogin = getFromSessionStorage('isLogin') || false
        if (this.isLogin){
            this.user = getFromSessionStorage('user')
            let arr = this.user.userInfoIdentifing.displayName.split(' ')
            this.name = arr[(arr.length-1)]
        }
    },

    data() {
        return {
            name : '',
            user : '',
            isLogin : false,
            isFixedHeader: false
        }
    },
    methods: {
        toggleCollapse() {
            this.$store.commit('setIsCollapse', !this.isCollapse)
        },
        handleNavigateHome() {
            console.log( this.$route.name);
            if (this.$route.name == 'Home') return;
            this.$router.push(path.home.home)
        },
        handleNavigateSignUp() {
            console.log( this.$route.name);
            if (this.$route.name == 'SignUp') return;
            this.$router.push(path.auth.signup)
        },
        handleNavigateSLogin(){
            console.log( this.$route.name);
            if (this.$route.name == 'Login') return;
            this.$router.push(path.auth.login)
        },
        handleNavigateSLogout(){
            deleteSessionStorage('user');
            deleteSessionStorage('isLogin');
            window.location.reload();
        }
    },
    computed: { ...mapState(['isCollapse']) }
}
</script>
<style scoped>
.header {
    background-color: #000000;
    position: sticky;
    top: 0;
    height: 60px;
    border-bottom: 1px solid #f5f6fa;
    z-index: 1001;
    width: 100%;
    transition: top 0.2s ease-in-out;
}

.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.header-layout {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
}

.header-left {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 60px;
}
.header-logo .img {
    padding: 5px 5px 5px 10px;
    height: 35px;
    object-fit: cover;
    cursor: pointer;
}
.header-logo h3 {
    font-size: 16px;
    color: #fff;
    border-bottom: 0 solid transparent;
}
.header-icon {
    padding: 0rem 0rem 0rem 1rem;
}
.header-icon .icon {
    color: #fff;
}
.header-icon:hover {
    transform: 0.3s;
    background-color: #367fa9;
    padding: 0rem 1rem 0rem 1rem;
}
.header-right {
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.language,
.download {
    padding: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.language:hover,
.download:hover {
    color: yellow;
}
.icon-menu {
    margin-left: 10px;
    cursor: pointer;
}
</style>

<template>
    <div class="background">
        <div class="container">
            <div class="row pt-5 align-items-center justify-content-center">
                <div class="left col-md-6 col-lg-6 col-xs-12">
                    <TitleHome title="Giới thiệu" sub="VFitness" />
                    <div class="sub-title">
                        VFitness là nền tảng fitness hàng đầu với ứng dụng và
                        trang web tiên tiến. Bạn có thể truy cập hàng ngàn bài tập
                        và kế hoạch luyện tập chuyên nghiệp, tùy chỉnh theo mục
                        tiêu và mức độ của bản thân. Hướng dẫn kỹ thuật chi tiết
                        và theo dõi tiến độ qua báo cáo sẽ giúp bạn tối ưu hóa
                        hiệu quả tập luyện. Bắt đầu hành trình fitness của bạn với
                        VFitness ngay!
                    </div>
                    <div
                        v-for="(item, index) in fakeData"
                        :key="index"
                        class="info"
                    >
                        <img src="@/assets/img/Ellipse.svg" class="info-img" />
                        <span class="info-description">
                            {{ item.description }}
                        </span>
                    </div>
                    <div class="layout-btn">
                        <CustomButton
                            label="Xem chi tiết "
                            size="medium"
                            class="btn"
                            type="text"
                        />
                    </div>
                    <div class="layout-icon">
                        <img src="@/assets/img/icon.svg" class="left-img" />
                        <div class="icon-right">
                            <img src="@/assets/img/icon1.svg" class="left-img" />
                        </div>
                    </div>
                </div>

                <div class="right col-md-4 col-lg-4 col-xs-12">
                    <img src="@/assets/img/TEST.svg" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import TitleHome from '@/components/TitleHome.vue'

export default {
    name: 'IntroducePage',
    components: { CustomButton, TitleHome },
    data() {
        return {
            fakeData: [
                {
                    description: 'Nền tảng hàng đầu'
                },
                {
                    description: 'Hàng ngàn bài tập và kế hoạch luyện tập'
                },
                {
                    description: 'Tùy chỉnh theo mục tiêu và trình độ'
                },
                {
                    description: 'Hướng dẫn kỹ thuật chi tiết'
                },
                {
                    description: 'Theo dõi tiến độ và báo cáo'
                },
                {
                    description: 'Khám phá và khởi đầu ngay'
                }
            ]
        }
    },
    methods: {}
}
</script>
<style scoped>
.background {
    background: url(@/assets/img/introduce.svg) center/cover no-repeat !important;

    background-size: cover;
    padding-bottom: 50px;
}

.sub-title {
    color: #191718;
    font-weight: 600;
    font-size: 15px;
}

.info {
    margin-top: 10px;
    margin-bottom: 10px;
}
.info-img {
    height: 20px;
}

.info-description {
    color: #191718;
    font-weight: 700;
    font-size: 15px;
    margin-left: 10px;
}
.left-img {
    width: 100%;
    object-fit: cover;
}
.right img {
    object-fit: cover;
    max-height: 600px;
}
.btn {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 800;
    padding: 10px 30px 10px 30px;
    border-radius: 8px !important;
    text-transform: uppercase;
}
.layout-icon {
    margin-top: 25px;

    display: flex;
    align-items: center; /* Căn giữa theo chiều dọc */
}

.layout-icon .left-img {
    width: 165px;
    object-fit: cover;
}
.layout-icon .icon-right {
    padding: 0px 0px 0px 25px;
}

@media (max-width: 768px) {
    .title {
        padding: 0px 0px 10px 0px;
    }
    .right {
        display: none;
    }
    .layout-icon img {
        margin-top: 0;
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .right {
        display: none;
    }
}
</style>

<template>
    <div class="payment">
        <div class="container">
            <div class="title">Thanh toán</div>
            <div class="hr">
                <hr />
            </div>
            <div class="body">
                <div class="content">
                    Thanh toán bằng thẻ ATM
                    <img :src="imgSrc" class="img-atm" />
                </div>
            </div>
            <div class="row form justify-content-between">
                <div class="col-md-6">
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-4">Chọn ngân hàng</div>
                        <div class="col-md-7">
                            <ESelect
                                size="medium"
                                style="width: 100%"
                                :clearable="false"
                                :disabled="false"
                                v-model="selectedValueBank"
                                :data="fakeDataBank"
                                placeholder="Chọn "
                                :filterable="true"
                                :collapseTags="false"
                                :fields="['name', 'id']"
                                :no-data-text="'Không có bản ghi nào'"
                                :no-match-text="'Không tìm thấy bản ghi nào'"
                            />
                        </div>
                    </div>

                    <div class="row mt-4 align-items-center">
                        <div class="col-md-1">
                            <i class="el-icon-arrow-down icon"> </i>
                        </div>
                        <div class="col-md-4">Tên chủ thẻ</div>
                        <div class="col-md-7">
                            <CustomInput
                                v-model="name"
                                placeholder=""
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                        </div>
                    </div>
                    <div class="row mt-4 align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-4">Số thẻ</div>
                        <div class="col-md-7">
                            <CustomInput
                                v-model="number"
                                placeholder=""
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                        </div>
                    </div>
                    <div class="row mt-4 align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-4">Ngày hết hạn</div>
                        <div class="col-md-3">
                            <ESelect
                                size="medium"
                                style="width: 100%"
                                :clearable="false"
                                :disabled="false"
                                v-model="selectedValueDate"
                                :data="fakeDataDate"
                                placeholder="Chọn "
                                :filterable="true"
                                :collapseTags="false"
                                :fields="['name', 'id']"
                                :no-data-text="'Không có bản ghi nào'"
                                :no-match-text="'Không tìm thấy bản ghi nào'"
                            />
                        </div>
                        <div class="col-md-3">
                            <ESelect
                                size="medium"
                                style="width: 100%"
                                :clearable="false"
                                :disabled="false"
                                v-model="selectedValueMonth"
                                :data="fakeDataDateMonth"
                                placeholder="Chọn "
                                :filterable="true"
                                :collapseTags="false"
                                :fields="['name', 'id']"
                                :no-data-text="'Không có bản ghi nào'"
                                :no-match-text="'Không tìm thấy bản ghi nào'"
                            />
                        </div>

                        <div class="col-md-1"></div>
                    </div>
                    <div class="row mt-4 align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-4">Mã bảo mật</div>
                        <div class="col-md-6">
                            <CustomInput
                                v-model="ma"
                                placeholder=""
                                :disabled="false"
                                :clearable="false"
                                size="medium"
                            >
                            </CustomInput>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="layout-radio">
                        <div class="pading-radio">
                            <el-radio v-model="radio"></el-radio>
                        </div>
                        <div class="month">Gói 3 tháng:</div>
                        <div class="title-radio">699k</div>
                        <div class="padding-btn">
                            <CustomButton
                                label="Thay đổi"
                                size="medium"
                                type="text"
                                class="btn-no-background btn-change"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="list-bank">
                        <div class="row p-4">
                            <div
                                class="col-md-6 mb-2"
                                v-for="(image, index) in images"
                                :key="index"
                            >
                                <img :src="image.imgSrc" class="img-bank" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="layout-btn">
                <CustomButton
                    label="Quay lại"
                    size="medium"
                    type="text"
                    class="btn-white custom-btn"
                />

                <CustomButton
                    label=" Xác nhận thanh toán"
                    size="medium"
                    type="text"
                    class="btn-red custom-btn"
                />
            </div>
        </div>
    </div>
</template>
<script>
import payment1 from '@/assets/img/payment1.svg'
import payment4 from '@/assets/img/payment4.svg'
import payment5 from '@/assets/img/payment5.svg'
import payment6 from '@/assets/img/payment6.svg'
import payment7 from '@/assets/img/payment7.svg'
import payment8 from '@/assets/img/payment8.svg'

import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import ESelect from '@/components/ESelect.vue'
export default {
    name: 'ATM',
    components: { CustomButton, CustomInput, ESelect },
    data() {
        return {
            radio: '',
            imgSrc: payment1,
            links: [],
            state: '',
            name: 'Nguyễn Văn A',
            number: '1234567890JQK',
            ma: '1234567890JQK',
            selectedValueBank: 2,
            fakeDataBank: [
                {
                    id: 1,
                    name: 'TP Bank'
                },
                {
                    id: 2,
                    name: 'Ngân hàng Kỹ thương Việt Nam - Techcombank'
                },
                {
                    id: 3,
                    name: 'VCB'
                }
            ],
            selectedValueDate: 2,
            fakeDataDate: [
                {
                    id: 1,
                    name: '01'
                },
                {
                    id: 2,
                    name: '02'
                },
                {
                    id: 3,
                    name: '03'
                }
            ],
            selectedValueMonth: 2,
            fakeDataDateMonth: [
                {
                    id: 1,
                    name: 'Tháng 1'
                },
                {
                    id: 2,
                    name: 'Tháng 2'
                },
                {
                    id: 3,
                    name: 'Tháng 3'
                }
            ],

            images: [
                { imgSrc: payment4 },
                { imgSrc: payment5 },
                { imgSrc: payment6 },
                { imgSrc: payment7 },
                { imgSrc: payment4 },
                { imgSrc: payment5 },
                { imgSrc: payment6 },
                { imgSrc: payment7 }
            ]
        }
    },
    methods: {}
}
</script>

<style scoped>
.payment {
    background: #303030 url(@/assets/img/background.svg) center/cover no-repeat;

    background-size: cover;
}
.title {
    font-weight: 900;
    color: #0276fa;
    font-size: 36px;
    padding-top: 30px;
}
.img-atm {
    padding: 0px 0px 22px 15px;
}

.hr {
    margin-top: 30px;
}
.hr hr {
    margin: 0;
    border: 0.1px solid #f5f7f78f;
    width: 75%;
}
.content {
    padding: 30px 0px 50px 0px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800;
}
.form {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 50px;
}
.layout-radio {
    display: flex;

    align-items: center;
    padding: 35px 0px 0px 45px;
}

.radio {
    margin-bottom: 35px;
}
.img {
    margin: -10px 30px 0;
    padding: 0px;
}
.btn-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn-no-background {
    color: #ffffff;
    border: 1px solid #0276fa;
}
.padding-btn {
    padding: 0px 0px 5px 0px;
}
.custom-btn {
    padding: 13px 63px;
    border-radius: 20px;
}
.btn-change {
    padding: 10px 50px;
    border-radius: 20px;
}
.layout-btn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}

::v-deep .el-autocomplete {
    width: 100%;
}
::v-deep .el-input__icon {
    font-weight: 700;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
    display: flex;
    background: #0276fa;
    height: 20px;
    width: 20px;
    padding: 0px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 50%;
}
.icon {
    font-size: 24px;
    font-weight: 800;
}
.title-radio {
    font-weight: 900;
    font-size: 30px;
    padding: 0px 30px 8px 20px;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #0276fa;
    background: #0276fa;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #ffffff;
}
.month {
    white-space: nowrap;
    letter-spacing: 2px;
    padding: 0px 15px 0px 15px;
}
.list-bank {
    background-color: #ffffff;
    border-radius: 8px;
}
@media (max-width: 767px) {
    .list-bank {
        margin-top: 20px;
    }
    .img-bank {
        width: 100%;
        object-fit: cover;
    }
    .layout-radio {
        padding: 15px 0px 0px 0px;
    }
    .month {
        padding: 0px 0px 10px 0px;
    }
    .padding-radio {
        padding: 0px 0px 10px 0px;
    }
    .title-radio {
        padding: 0px 10px 12px 10px;
        font-size: 24px;
    }
    .padding-btn {
        padding: 0px 0px 12px 0px;
    }
}
</style>

<template>
    <footer class="footer bg-blue white-text">
        <div class="row">
            <div class="col-md-3 d-flex align-items-center">
                <img src="@/assets/img/Asset 1 1.png" class="img" />
                <img src="@/assets/img/footer2.svg" class="img" />
            </div>
            <div class="col-md-3 col-12">
                <div class="title">Thông tin liên hệ</div>
                <div
                    v-for="(item, index) in footerInfo"
                    :key="index"
                    class="row justify-content-center align-items-center mt-4"
                >
                    <div class="col-2 col-2-mobile">
                        <img :src="item.image" class="image-contact" />
                    </div>
                    <div class="col-10 col-10-mobile text-contact">
                        {{ item.text }}
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="title">Tải ứng dụng tại</div>
                <div style="display: flex">
                    <a href="https://apps.apple.com/vn/app/vfitness/id1326812984?l=vi" target="_blank">
                        <img src="@/assets/img/footer4.svg" class="img-mobi" />
                    </a>
                    <img src="@/assets/img/footer5.svg" class="img-mobi" />
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="title">VFITNESS</div>
                <div class="title">KEEP IT FIT</div>
                <div class="mt-3 btn-mobi">
                    <CustomButton
                        icon="el-icon-d-arrow-right"
                        label="Tham gia ngay "
                        size="medium"
                        class="btn"
                        type="text"
                    />
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import CustomButton from './CustomButton.vue'
import footer7 from '@/assets/img/footer7.svg'
import footer6 from '@/assets/img/footer6.svg'
import footer3 from '@/assets/img/footer3.svg'

export default {
    name: 'FooterComponent',
    components: { CustomButton },

    data() {
        return {
            footerInfo: [
                {
                    image: footer7,
                    text: '+0908xxxxxxxx'
                },
                {
                    image: footer6,
                    text: 'vfitness@gmail.com'
                },
                {
                    image: footer3,
                    text: 'Địa chỉ web'
                }
            ]
        }
    },

    methods: {},

    computed: {}
}
</script>

<style scoped>
.bg-blue {
    padding: 30px 0 30px;
    background-color: #0276fa;
}

.white-text {
    color: #ffffff;
}

.img {
    margin-left: 20px;
    height: 25px;
    object-fit: cover;
}

.title {
    font-size: 22px;
    font-weight: bold;
}

.image-contact {
    width: 25px;
    object-fit: cover;
}

.text-contact {
    font-size: 14px;
}

.btn {
    background-color: #21242a;
    color: #ffffff;
    font-weight: bold;
    padding: 15px 40px;
    border-radius: 20px;
    text-transform: uppercase;
    border: 2px solid #ffffff;
}

.img-mobi {
    height: 150px;
    width: 150px;
}

@media (max-width: 767px) {
    .image-contact {
        width: 50%;
        object-fit: cover;
    }

    .img-mobi {
        margin: 0;
    }

    .title {
        font-size: 22px;
        margin-top: 20px;
        text-align: center;
    }

    .col-2-mobile {
        flex-basis: 100%;
        text-align: center;
    }

    .col-10-mobile {
        flex-basis: 50%;
        text-align: center;
    }

    .mt-4-mobile {
        margin-top: 1rem;
    }

    .btn-mobi {
        text-align: center;
    }

    .footer {
        display: none;
    }
}
</style>

import { render, staticRenderFns } from "./ListPackage.vue?vue&type=template&id=f6be99ba&scoped=true&"
import script from "./ListPackage.vue?vue&type=script&lang=js&"
export * from "./ListPackage.vue?vue&type=script&lang=js&"
import style0 from "./ListPackage.vue?vue&type=style&index=0&id=f6be99ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6be99ba",
  null
  
)

export default component.exports
<template>
    <div class="step3">
        <TitleSurvey :title="'Cơ thể hiện tại'" />
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                    <img :src="getCurrentBodyInfo.img" class="img-body" />
                </div>
                <div class="col-sm-6">
                    <div class="row d-flex flex-column">
                        <div>
                            <el-slider
                                v-model="currentBodyType"
                                :step="10"
                                show-stops
                                :show-tooltip="false"
                                :max="70"
                            ></el-slider>
                        </div>
                        <div class="box mt-4">
                            <div class="box-header">
                                <h4>Lượng mỡ cơ thể ước tính</h4>
                            </div>
                            <div class="box-body">
                                <div
                                    class="title-body"
                                    :style="{ color: getCurrentBodyInfo.color }"
                                >
                                    {{ getCurrentBodyInfo.title }}
                                </div>
                                <div class="sub-body">
                                    {{ getCurrentBodyInfo.detail }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Tiếp tục"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
        <ElDialog
            :title="'Thông báo'"
            :message="messageDialog"
            :visible.sync="dialogVisible"
            :textBtn="'Thực hiện lại'"
            :textBtnClose="'Không, tiếp tục'"
            :nextStep="true"
            :showButton="true"
            @confirm="handleConfirmAction"
            @nextStep="handleNextSteplAction"
        />
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import body0 from '@/assets/img/step4/body0.svg'
import body1 from '@/assets/img/step4/body1.svg'
import body2 from '@/assets/img/step4/body2.svg'
import body3 from '@/assets/img/step4/body3.svg'
import body4 from '@/assets/img/step4/body4.svg'
import body5 from '@/assets/img/step4/body5.svg'
import body6 from '@/assets/img/step4/body6.svg'
import body7 from '@/assets/img/step4/body7.svg'
import TitleSurvey from '@/components/TitleSurvey.vue'
import { mapState } from 'vuex'
import ElDialog from '@/components/ElDialog.vue'

export default {
    name: 'StepFour',
    components: { CustomButton, TitleSurvey, ElDialog },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            messageDialog: '',
            dialogVisible: false,
            currentBodyType: 20,
            list_body_type: [
                {
                    id: 1,
                    img: body0,
                    title: '5-9%',
                    detail: 'Đây là mức mỡ rất thấp và thường chỉ thấy ở các vận động viên cạnh tranh hoặc người rèn luyện chuyên sâu. Mức mỡ này đòi hỏi sự kiểm soát chặt chẽ và có thể có ảnh hưởng đến sức khỏe và cơ bắp',
                    color: '#E11238'
                },
                {
                    id: 2,
                    img: body1,
                    title: '10-14%',
                    detail: 'Đây là mức mỡ thấp và thường thấy ở những người có một chế độ tập luyện đều đặn và cân đối. Mức mỡ này thường đi kèm với một cơ thể săn chắc và thể hình tốt',
                    color: '#FD9A10'
                },
                {
                    id: 3,
                    img: body2,
                    title: '15-19%',
                    detail: 'Đây là mức mỡ trung bình và thường thấy ở những người có một cơ thể khỏe mạnh và thể hình tốt. Mức mỡ này bình thường và không gây ảnh hưởng tiêu cực đáng kể đến sức khỏe',
                    color: '#00B412'
                },
                {
                    id: 4,
                    img: body3,
                    title: '20-24%',
                    detail: 'Đây là mức mỡ trung bình đến cao và thường thấy ở những người có một chút mỡ thừa và không quá săn chắc. Mức mỡ này không gây ảnh hưởng tiêu cực đáng kể đến sức khỏe, nhưng có thể muốn giảm mỡ để đạt được một cơ thể săn chắc hơn',
                    color: '#014CFF'
                },
                {
                    id: 5,
                    img: body4,
                    title: '25-29%',
                    detail: 'Đây là mức mỡ cao và thường thấy ở những người có một lượng mỡ thừa đáng kể và không có cơ thể săn chắc. Mức mỡ này có thể tăng nguy cơ mắc một số vấn đề về sức khỏe, như bệnh tim mạch và tiểu đường',
                    color: '#FCCB1B'
                },
                {
                    id: 6,
                    img: body5,
                    title: '30-34%',
                    detail: 'Đây là mức mỡ rất cao và thường thấy ở những người có một lượng mỡ thừa nhiều và cơ thể mất đi sự định hình. Mức mỡ này có thể làm gia tăng nguy cơ mắc các vấn đề sức khỏe nghiêm trọng',
                    color: '#F99304'
                },
                {
                    id: 7,
                    img: body6,
                    title: '35-39%',
                    detail: 'Mức mỡ cơ thể quá cao. Mức mỡ này có thể gây nguy cơ cao về sức khỏe và tăng khả năng mắc các bệnh mạn tính',
                    color: '#EC573D'
                },
                {
                    id: 8,
                    img: body7,
                    title: '35-39%',
                    detail: 'Đây là mức mỡ cơ thể quá cao. Mức mỡ này có thể gây nguy cơ cao về sức khỏe và tăng khả năng mắc các bệnh mạn tính, bao gồm bệnh tim mạch, tiểu đường, cao huyết áp và béo phì',
                    color: '#F02502'
                }
            ]
        }
    },
    computed: {
        getCurrentBodyInfo() {
            return this.list_body_type[this.currentBodyType / 10]
        },
        ...mapState({
            BMI: (state) => state.surveyData.bmi
        })
    },
    methods: {
        handleConfirmAction() {
            this.dialogVisible = false
        },
        handleNextSteplAction(){
            this.$emit('handleNextStep')
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'currentBodyType',
                data: this.currentBodyType / 10
            })

            let convertCurrentBodyType = this.currentBodyType / 10

            if (convertCurrentBodyType === 1 && this.BMI > 18.5) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức rất gầy, và cơ thể hiện tại đang ở mức béo. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (convertCurrentBodyType === 2 && this.BMI > 18.5) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức gầy, và cơ thể hiện tại đang ở mức béo. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (
                convertCurrentBodyType === 3 &&
                (this.BMI < 25 || this.BMI > 18.5)
            ) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức bình thường, và cơ thể hiện tại đang ở mức gầy. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (
                convertCurrentBodyType === 4 &&
                (this.BMI < 35 || this.BMI > 18.5)
            ) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức gầy, và cơ thể hiện tại đang ở mức bình thường. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (
                convertCurrentBodyType === 5 &&
                (this.BMI < 40 || this.BMI > 30)
            ) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức béo, và cơ thể hiện tại đang ở mức bình thường. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (convertCurrentBodyType === 6 && this.BMI < 35) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức bình thường, và cơ thể hiện tại đang ở mức gầy. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (convertCurrentBodyType === 7 && this.BMI < 35) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức béo, và cơ thể hiện tại đang ở mức gầy. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else if (convertCurrentBodyType === 8 && this.BMI < 40) {
                this.dialogVisible = true
                this.messageDialog =
                    'Chỉ số BMI của bạn đang ở mức gầy, và cơ thể hiện tại đang ở mức bình th. Lựa chọn của bạn chưa hợp lý. Bạn có muốn chọn lại không?'
            } else {
                this.$emit('handleNextStep')
            }
        }
    }
}
</script>

<style scoped>
.step3 {
    /* height: 100vh; */
}

.img-body {
    object-fit: cover;
}

::v-deep .el-slider__bar {
    background-color: #ffffff;
}

.box {
    background-color: #f5f7f7;
    border-radius: 8px;
}

.box-header {
    padding: 15px 10px 10px 20px;
    background-color: #8080805c;
}

.box-header h4 {
    color: #2e2b2e;
    font-weight: 700;
    font-size: 16px;
}

.box-body {
    padding: 15px 10px 30px 20px;
}

.title-body {
    font-weight: 700;
    font-size: 15px;
}

.sub-body {
    margin-top: 15px;
    color: #2e2b2e;
    font-weight: 600;
    height: 120px;
    font-size: 14px;
}

.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn {
    padding: 13px 120px 13px 120px;
    border-radius: 20px;
    text-transform: uppercase;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
}

::v-deep .el-slider__stop {
    background-color: #007bff8a;
    height: 20px;
    width: 20px;
    margin-top: 0px;
}

::v-deep .el-slider__runway {
    height: 20px;
    border-radius: 12px;
}

::v-deep .el-slider__bar {
    height: 20px;

    border-radius: 12px 0px 0px 12px;
}

::v-deep .el-slider__button-wrapper {
    top: -8px;
}

::v-deep .el-slider__button {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .btn {
        padding: 13px 50px 13px 50px;
    }
}
</style>

<template>
    <div class="background">
        <Banner />

        <Introduce />

        <PlanIntro ref="planIntroRef" />
        <FeedBack />
        <!-- <div class="container">
            <Pay />
        </div> -->
        <Join />
    </div>
</template>
<script>
import Introduce from './Introduce.vue'
import Join from './Join.vue'
import PlanIntro from './PlanIntro/index.vue'
import FeedBack from './FeedBack.vue'
import Pay from './Pay.vue'
import Banner from './Banner.vue'

export default {
    name: 'HomePage',
    components: { Banner, Introduce, PlanIntro, FeedBack, Join },
    data() {
        return {}
    },
    methods: {
        scrollToPay() {
            // Sử dụng $refs để truy cập PlanIntro component và gọi phương thức scrollToPay
            this.$refs.planIntroRef.scrollToPay()
        }
    }
}
</script>

<style scoped>
.background {
    /* background-color: #000000; */
    background: url(@/assets/img/introduce/Rectangle.svg) center/cover no-repeat;
}
</style>

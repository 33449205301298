<template>
    <div class="bg">
        <div class="container">
            <div class="pt-5 mb-5 layout-common">
                <img src="@/assets/img/Asset 1 1.png" class="img" />

                <img src="@/assets/img/VFITNESS.svg" class="img" />
            </div>

            <div class="mb-5 layout-common">
                <img src="@/assets/img/hr.svg" class="img-hr" />
            </div>
            <div class="layout-common">
                <h4>
                    BẠN ĐÃ ĐĂNG KÝ TÀI <br />
                    KHOẢN THÀNH CÔNG!
                </h4>
            </div>
            <div class="text-center">
                <div class="layout-btn">
                    <CustomButton
                            :label="'Tham gia khảo sát'"
                            size="medium"
                            type="primary"
                            class="btn"
                            @click="handleNavigateToSurvey"
                    />
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-5 mb-5">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">
                    <div class="layout-img">
                        <div>
                            Tải trên <br />
                            App Store
                        </div>
                        <a href="https://apps.apple.com/vn/app/vfitness/id1326812984?l=vi" target="_blank">
                            <img src="@/assets/img/signup/store.svg" />
                        </a>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="layout-img">
                        <div>
                            Tải trên <br />
                            GooglePlay
                        </div>
                        <div>
                            <img src="@/assets/img/signup/ggplay.svg" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import {path} from "@/router/path";
export default {
    name: 'PasswordSuccess',
    components: {CustomButton},
    data() {
        return {}
    },
    methods: {
        handleNavigateToSurvey() {
            this.$router.push(path.survey)
        },
    }
}
</script>

<style scoped>
.bg {
    background: #303030 url(@/assets/img/background.svg) center/cover no-repeat;
    /* height: 100vh; */
}
.container {
    padding-left: 0px;
    padding-right: 0px;
}
.layout-common {
    display: flex;
    justify-content: center;
}
.img-hr {
    object-fit: cover;
    width: 100%;
}

.layout-common h4 {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
}

.layout-common p {
    color: #ffffff;
    margin-bottom: 0;
    font-family: cursive, sans-serif;
    text-align: center;
}
.bg-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}
.bg-while {
    color: #303030;
    background-color: #ffffff;
    font-weight: 700;
}
.layout-img {
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f5f7f7;
    padding: 10px 50px 10px 50px;
    text-align: center;
}
.btn {
    margin: 0px 20px 0px 20px;
    padding: 13px 120px;
    border-radius: 8px;
}
.padding-80 {
    padding-bottom: 80px;
}
</style>

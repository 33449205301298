<template>
    <div class="title-survey">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'TitleSurvey',
    props: {
        title: {
            type: String
        }
    }
}
</script>

<style scoped>
.title-survey {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 30px;
}
</style>

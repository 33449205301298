export function validateInput(type, value, password) {
    switch (type) {
        case 'text': {
            const textPattern = /^[A-Za-z0-9\s]+$/
            if (!textPattern.test(value)) {
                return 'Vui lòng nhập văn bản hợp lệ.'
            }
            break
        }

        case 'phone': {
            const phonePattern = /^[0-9]{10}$/
            if (!phonePattern.test(value)) {
                return 'Số điện thoại không hợp lệ.'
            }
            break
        }

        case 'email': {
            const emailPattern =
                /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
            if (!emailPattern.test(value)) {
                return 'Email không hợp lệ.'
            }
            break
        }

        case 'password':
            if (value.length < 6) {
                return 'Mật khẩu phải có ít nhất 6 ký tự.'
            }
            break

        case 'retypePassword':
            if (value !== password) {
                return 'Mật khẩu nhập lại không trùng khớp.'
            }
            break

        case 'code': {
            const codePattern = /^[A-Za-z0-9]+$/
            if (!codePattern.test(value)) {
                return 'Mã code không hợp lệ.'
            }
            break
        }

        default:
            return 'Loại dữ liệu không hợp lệ.'
    }
}

export function changeValidateInput(fieldName, value, otherValue, errorState) {
    let error = ''

    switch (fieldName) {
        case 'text':
            error = validateInput('text', value)
            break
        case 'retypePassword':
            error = validateInput('retypePassword', value, otherValue)
            break
        case 'password':
            error = validateInput('password', value, otherValue)
            break
        default:
            error = 'Loại dữ liệu không hợp lệ.'
    }

    errorState[`${fieldName}Error`] = error

    if (!error) {
        errorState[`${fieldName}Error`] = '' // Hide the error message
    }
}

export const filterItemsByType = (array, targetType) => {
    const filteredItems = array.filter((item) => item.type === targetType)
    return filteredItems.length > 0 ? filteredItems : []
}

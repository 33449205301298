<template>
    <div class="banner">
        <VueSlickCarousel v-bind="settings" ref="carousel">
            <div class="carousel" v-for="(item, index) in fakeData" :key="index">
                <div class="img-container">
                    <img :src="item.image" class="img" />
                    <img src="@/assets/img/banner1.svg" class="banner-image" />
                </div>

                <div class="content">
                    <div class="mobi-img-arrow">
                        <img
                            src="@/assets/img/left-arrow.svg"
                            class="img-arrow"
                            @click="showNext"
                        />
                    </div>

                    <div class="container slick">
                        <div
                            class="row align-items-center justify-content-center"
                        >
                            <div class="col-md-10 col-lg-10 col-xs-12">
                                <div class="title">{{ item.title }}</div>
                                <div class="sub-title">
                                    {{ item.description }}
                                </div>
                                <div class="layout-btn">
                                    <CustomButton
                                        :label="item.textBtn"
                                        size="medium"
                                        type="text"
                                        class="btn"
                                        @click="handleNavigateToSurvey"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobi-img-arrow">
                        <img
                            src="@/assets/img/right-arrow.svg"
                            class="img-arrow"
                            @click="showNext"
                        />
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <ElDialog
            :title="'Thông báo'"
            :message="messageDialog"
            :visible.sync="dialogVisible"
            :textBtn="'Đăng nhập'"
            :showButton="true"
            @confirm="handleConfirmAction"
        />
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import CustomButton from '@/components/CustomButton.vue'
import banner2 from '@/assets/img/banner/banner2.svg'
import banner1 from '@/assets/img/banner/banner1.svg'
import banner from '@/assets/img/banner.svg'
import { path } from '@/router/path'
import { getFromSessionStorage } from '@/utils/session'
import ElDialog from '@/components/ElDialog.vue'

export default {
    name: 'BanNer',
    components: { ElDialog, VueSlickCarousel, CustomButton },
    data() {
        return {
            dialogVisible: false,
            messageDialog: '',
            settings: {
                dots: true,
                arrows: false,

                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,

                autoplaySpeed: 2000,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            initialSlide: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },
            fakeData: [
                {
                    image: banner,
                    title: 'STAY FIT',
                    textBtn: 'Tham gia ngay',
                    description:
                        'VFitness là nền tảng fitness hàng đầu với ứng dụng và trang web tiên tiến. Bạn có thể truy cập hàng ngàn bài tập và kế hoạch luyện tập chuyên nghiệp, tùy chỉnh theo mục tiêu và mức độ của bản thân. Hướng dẫn kỹ thuật chi tiết và theo dõi tiến độ qua báo cáo sẽ giúp bạn tối ưu hóa hiệu quả tập luyện. Bắt đầu hành trình fitness của bạn với VFitness ngay!'
                },
                {
                    image: banner1,
                    title: 'CƠ THỂ SĂN CHẮC',
                    textBtn: 'Tập ngay',
                    description:
                        'Nói chung, để có một cơ thể rắn chắc và săn chắc, việc kết hợp giữa chế độ ăn uống cân đối và lịch trình tập luyện đa dạng là quan trọng. Bài tập để làm cho cơ bắp săn chắc không chỉ giúp tăng cường sức mạnh cơ bắp mà còn giúp tạo ra hình thể đẹp và cân đối.'
                },
                {
                    image: banner2,
                    title: 'GIẢM CÂN HIỆU QUẢ',
                    textBtn: 'Tập ngay',
                    description:
                        'Bài tập giảm cân không chỉ giúp đốt cháy calo mà còn tăng cường sức khỏe toàn diện. Đối với sự đa dạng trong lịch trình tập luyện, người dùng có thể kết hợp giữa cardio, tập luyện sức mạnh và hoạt động nhóm như yoga để cải thiện sự linh hoạt và giảm căng thẳng. Ứng dụng VFitness không chỉ là nguồn cung cấp thông tin và kế hoạch tập luy ện mà còn là nguồn động viên và hỗ trợ quan trọng trong hành trình giảm cân của người dùng.'
                }
            ]
        }
    },
    methods: {
        handleNavigateToSurvey() {
            this.isLogin = getFromSessionStorage('isLogin') || false
            if (this.isLogin) {

                this.$router.push(path.survey)
            } else {
                this.dialogVisible = true
                this.messageDialog = 'Bạn cần đăng nhập trước khi khảo sát';
            }
        },
        handleConfirmAction() {
            this.$router.push(path.auth.login)
        },
        showNext() {
            this.$refs.carousel.next()
        }
    }
}
</script>

<style scoped>
.banner {
    min-height: 100vh;
    padding-bottom: 50px;
    background: url(@/assets/img/banner1.svg) center/cover no-repeat;
}

.content {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 20px;
}

.img-container {
    position: relative;
}

.img {
    height: 100%;
    position: relative;
    width: 100%;
}

.banner-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; /* Đảm bảo rằng hình ảnh banner sẽ có kích thước bằng với "img" */
}

.slick {
    margin: 0;
}

.slick .title {
    font-size: 28px;
    color: #ffffff;
    font-weight: 900;
    margin-bottom: 10px;
}

.slick .sub-title {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
}

.slick .layout-btn {
    margin-top: 25px;
}

.slick .btn {
    background-color: #0276fa;
    color: #ffffff;
    border-radius: 10px;
    font-weight: 700;
    padding: 15px 60px;
    text-transform: uppercase;
}

.img-arrow {
    cursor: pointer;
    height: 35px;
    object-fit: cover;
}

::v-deep .slick-dots li.slick-active button:before {
    margin-top: 10px;
    color: #0276fa;
}

::v-deep .slick-dots li button:before {
    margin-top: 10px;
    font-size: 14px;

    color: #ffffff;
}

@media (max-width: 767px) {
    .banner {
        height: auto;
        padding-bottom: 50px;
        min-height: 0;
    }

    .main {
        background: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        height: auto;
    }

    .content {
        top: 0;
        padding: 0;
    }

    .sub-title {
        display: none;
    }

    .mobi-img-arrow {
        display: none;
    }

    .slick .layout-btn {
        margin-top: 0;
    }

    .slick .btn {
        padding: 10px 30px;
    }

    .col-md-7 {
        text-align: left;
    }

    .slick {
        margin: 0;
    }
}
</style>

<template>
    <div>
        <div class="container">
            <TitleHome title="Mô tả" sub="chương trình" />
        </div>
        <ListData @scrollToPay="scrollToPay" />
    </div>
</template>
<script>
import TitleHome from '@/components/TitleHome.vue'
import ListData from './ListData.vue'

export default {
    name: 'PlanIntro',
    components: { ListData, TitleHome },
    data() {
        return {}
    },
    methods: {
        scrollToPay() {
            const payElement = document.getElementById('pay-section')
            if (payElement) {
                payElement.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
}
</script>

<style scoped>
.container {
    padding-top: 30px;
}
.list-video {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .container {
        padding-top: 0;
    }
}
</style>

<template>
    <div class="feedback">
        <div class="container">
            <TitleHome title="Phản hồi từ" sub="Khách hàng" />
        </div>
        <div class="body">
            <div>
                <img
                    src="@/assets/img/left-arrow.svg"
                    class="img-arrow"
                    @click="showNextListFree"
                />
            </div>
            <div class="container slick">
                <VueSlickCarousel v-bind="settings" ref="carouselListFree">
                    <div
                        v-for="item in fakeData"
                        :key="item.id"
                        class="list-data"
                    >
                        <div class="layout-data bg-1">
                            <div class="layout-img">
                                <img
                                    src="@/assets/img/feedback1.svg"
                                    class="img"
                                />
                            </div>
                            <div class="layout-content">
                                <div class="exercise-content">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="layout-rate">
                                <el-rate v-model="item.id"></el-rate>
                            </div>
                            <div class="layout-name">
                                <div class="exercise-name">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
            <div>
                <img
                    src="@/assets/img/right-arrow.svg"
                    class="img-arrow"
                    @click="showNextListFree"
                />
            </div>
        </div>
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TitleHome from '@/components/TitleHome.vue'

export default {
    name: 'FeedBack',
    components: { TitleHome, VueSlickCarousel },
    data() {
        return {
            settings: {
                dots: true,
                arrows: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },

            fakeData: [
                {
                    id: 1,
                    title: '"VFitness thực sự ấn tượng! Tôi đã dành nhiều thời gian tìm kiếm một ứng dụng luyện tập phù hợp và cuối cùng, tôi đã tìm thấy. Giao diện trực quan và dễ sử dụng giúp tôi thiết lập kế hoạch luyện tập cá nhân một cách dễ dàng. Có đa dạng các bài tập và chế độ luyện tập, từ cardio đến tập sức mạnh, đáp ứng mọi nhu cầu của tôi. Tôi thích cách ứng dụng theo dõi tiến độ của tôi và đề xuất bài tập mới thú vị hàng ngày. Điều này thực sự giúp tôi duy trì động lực và tạo động lực để tiếp tục luyện tập mỗi ngày. Tôi rất hài lòng và không thể chờ đợi để thấy sự tiến bộ của mình!"',
                    name: 'Tommy Xiaomi.'
                },
                {
                    id: 2,
                    title: 'Bài Tập Ngực',
                    name: 'Tommy Xiaomi'
                },
                {
                    id: 3,
                    title: 'Bài Tập Lưng',
                    name: 'Tommy Xiaomi'
                },
                {
                    id: 4,
                    title: 'Bài Tập Lưng',
                    name: 'Tommy Xiaomi'
                }
            ],
            a: 3
        }
    },
    methods: {
        showNextListFree() {
            this.$refs.carouselListFree.next()
        }
    }
}
</script>

<style scoped>
.feedback {
    padding-bottom: 100px;
    margin-top: 100px;
    background: url(@/assets/img/feedback.svg) center/cover no-repeat;
}
.body {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-data {
    padding: 0px 5px 0px 5px;
}
.layout-data {
    padding: 20px 0px 20px 0px;
    border-radius: 20px;
}
.bg-1 {
    background-color: #f5f7f7;
}

.layout-img {
    display: flex;
    justify-content: center;
}
.layout-content,
.layout-name,
.layout-rate {
    display: flex;
    justify-content: center;
}
.layout-name {
    margin-top: 15px;
}
.exercise-content {
    text-align: justify;
    font-size: 14px;
    padding: 25px;
    color: #191718;
}
.slick {
    margin: 0px 30px 0px 30px;
}
.exercise-name {
    color: #191718;
    font-weight: 700;
}
.icon {
    width: 100%;
    height: 200px;
    object-fit: cover;
    image-rendering: pixelated;
    border-radius: 24px 24px 0px 0px;
    position: absolute;
    top: -200px;
    left: 0;
}

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #0276fa;
    border-radius: 50%;
    margin-right: 5px;
}
.img-arrow {
    cursor: pointer;
    height: 40px;
    object-fit: cover;
}
::v-deep .slick-dots li.slick-active button:before {
    margin-top: 10px;
    color: #0276fa;
}
::v-deep .slick-dots li button:before {
    margin-top: 10px;
    font-size: 14px;

    color: #ffffff;
}
::v-deep .el-icon-star-on:before {
    color: #0276fa;
}
::v-deep .slick-track[data-v-e4caeaf8] {
    display: flex;
    align-items: center;
}
@media (max-width: 767px) {
    .img-arrow {
        display: none;
    }
}
</style>

<template>
    <div class="bg">
        <div class="container">
            <div class="pt-5 mb-5 layout-common">
                <img src="@/assets/img/Asset 1 1.png" class="img" />

                <img src="@/assets/img/VFITNESS.svg" class="img" />
            </div>

            <div class="mb-5 layout-common">
                <img src="@/assets/img/hr.svg" class="img-hr" />
            </div>

            <div v-if="!isHiddenOtp">
                <div class="layout-common mb-5">
                    <h4>Đăng ký tài khoản</h4>
                </div>
                <el-form  ref="formReg" :rules="rules" :model="form" label-width="120px" class="form-register">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-1 phone">

                                <el-form-item prop="sdt" label="Số điện thoại">
                                    <el-input
                                            placeholder="Nhập số điện thoại"
                                            v-model="form.sdt"
                                            size="medium"
                                    >
                                        <template slot="prepend">+84</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 phone">

                                <el-form-item prop="name" label="Họ tên">
                                    <el-input
                                            placeholder="Nhập họ tên"
                                            v-model="form.name"
                                            size="medium"
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 phone">

                                <el-form-item prop="dob" label="Ngày sinh">
                                    <el-date-picker type="date" placeholder="Ngày sinh" v-model="form.dob" style="width: 100%;"   value-format="timestamp"
                                                    format="dd/MM/yyyy"></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 phone">

                                <el-form-item prop="email" label="Email">
                                    <el-input
                                            placeholder="Email"
                                            v-model="form.email"
                                            size="medium"
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-1 phone">

                                <el-form-item label="Giới tính" prop="sex">
                                    <el-radio-group v-model="form.sex">
                                        <el-radio :label="0">Nam</el-radio>
                                        <el-radio :label="1">Nữ</el-radio>
<!--                                        <el-radio :label="2">Khác</el-radio>-->
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </el-form>

                <div class="text-center text-white">
                    <div>Lưu ý: Số điện thoại này dùng  để đăng ký tài khoản.</div>
                    <div>Vui lòng nhập chính xác số điện thoại của bạn!</div>
                </div>
                <div class="layout-common pt-4 pb-4">
                    <CustomButton
                            label="Đăng ký"
                            size="medium"
                            type="text"
                            class="btn bg-red"
                            @click="handleSignUp"
                    />
                </div>
            </div>

            <div v-else>
                <div v-if="isHiddenOtp === true" class="layout-common pt-3">
                    <p>Vui lòng nhập mã OTP được gửi về số điện thoại</p>
                </div>
                <div v-if="isHiddenOtp === true" class="layout-common mb-4">
                    <p>
                        {{ maskedPhoneNumber(this.sdt) }} để đăng nhập dịch vụ của
                        VFITNESS
                    </p>
                </div>
                <div v-if="isHiddenOtp === true" class="layout-common mt-4 otp">
                    <CustomInput
                            style="width: 45%"
                            v-model="otp"
                            placeholder="Nhập mã OTP"
                            :disabled="false"
                            :clearable="false"
                            size="medium"
                    >
                    </CustomInput>
                </div>
                <div v-if="isHiddenOtp === true" class="layout-common pt-4 mb-4">
                    <el-button @click="resendOtpCode" type="info" class="btn">
                        Gửi lại mã
                        <span v-if="countdownTime !== 60">({{ countdownTime }})</span>
                    </el-button>
                </div>
                <div
                        v-if="isHiddenOtp === true"
                        class="layout-common pt-2"
                        style="padding-bottom: 80px"
                >
                    <CustomButton
                            @click="getSignUpVerification"
                            label="Xác nhận"
                            size="medium"
                            type="text"
                            class="btn bg-red"
                    />
                </div>

            </div>





        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import Api from '@/constants/Api'
import { path } from '@/router/path'
import sendRequest from '@/services'
import { validateInput } from '@/utils/common'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

export default {
    name: 'SignUp',
    components: { CustomButton, CustomInput },
    data() {
        return {
            form: {
                sdt : '',
                dob : '',
                name : '',
                email : '',
                sex : '',
            },
            rules : {
                sex: [
                    { required: true, message: 'Vui lòng chọn giới tính', trigger: 'blur' },
                ],
                sdt : [
                    { required: true, message: 'Vui lòng nhập số điện thoại', trigger: 'blur' },
                    { pattern: /^\d{10,12}$/, message: 'Số điện thoại phải có từ 10 đến 12 số', trigger: 'blur' }
                ],
                name : [
                    { required: true, message: 'Vui lòng nhập họ tên', trigger: 'blur' },
                    { max: 100, message: 'Họ tên không vượt quá 100 ký tự', trigger: 'blur' },

                ],
                dob : [
                    { required: true, message: 'Vui lòng thêm ngày sinh', trigger: 'blur' },


                ],
                email: [
                    { required: true, message: 'Vui lòng nhập địa chỉ email', trigger: 'blur' },
                    { type: 'email', message: 'Địa chỉ email không đúng định dạng', trigger: ['blur', 'change'] }
                ]
            },
            sdt: '',
            otp: '',
            sessionId: '',
            errPhone: '',
            isHiddenOtp: false,
            countdownTime: 60, // Thời gian đếm ngược ban đầu là 60 giây
            countdownInterval: null // Lưu trữ interval cho countdown
        }
    },
    methods: {
        goToTop() {
            // Sử dụng window.scrollTo để cuộn lên đầu trang
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Cuộn mượt
            });
        },
        validate() {
            const phoneError = validateInput('phone', this.sdt)
            if (phoneError) {
                this.errPhone = phoneError
                return false
            } else {
                this.errPhone = ''
                return true
            }
        },

        maskedPhoneNumber() {
            if (this.sdt && this.sdt.length >= 10) {
                const hiddenDigits = 'xxxxx'
                const visibleDigits =
                    this.sdt.slice(0, 3) + hiddenDigits + this.sdt.slice(8)
                return visibleDigits
            } else {
                return 'Số điện thoại không hợp lệ'
            }
        },
        async signUp(){
            const requestData = {
                date_of_birth: this.form.dob,
                full_name: this.form.name,
                gender: this.form.sex,
                email: this.form.email,
                phone_number: this.form.sdt,
            }
            const response = await sendRequest(
                Api.auth.signup.authentication,
                requestData
            )
            console.log(response)

            if (response.code === 200) {

                this.sessionId = response.sessionId
                if (response.use_otp_verify){
                    this.goToTop()
                    this.isHiddenOtp = true
                    this.getOtp()
                }else{
                    this.$router.push({
                        path: path.auth.password,
                        query:{
                            phone : this.form.sdt,
                            sessionId: this.sessionId,
                        }
                    })
                }

            }else{
                this.$alert(response.message, 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    center: true,
                    type: 'warning',
                    callback: action => {
                    }
                });
            }
        },
        async handleSignUp() {

            this.$refs['formReg'].validate((valid) => {
                if (valid) {
                    try {
                        this.signUp()
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            return;

            // const isValid = this.validate()
            // if (isValid) {
            //     try {
            //         await this.validate()
            //         const requestData = {
            //             emailOrPhone: this.sdt
            //         }
            //         const response = await sendRequest(
            //             Api.auth.signup.authentication,
            //             requestData
            //         )
            //         console.log(response)
            //         if (response.code === 400) {
            //             this.errPhone = response.message
            //         }
            //         if (response.code === 200) {
            //             this.isHiddenOtp = true
            //             this.errPhone = ''
            //             this.sessionId = response.sessionId
            //             this.getOtp()
            //         }
            //     } catch (error) {
            //         console.log(error)
            //     }
            // } else {
            //     return
            // }
        },
        async getOtp() {
            const requestData = {
                sessionId: this.sessionId
            }
            const response = await sendRequest(
                Api.auth.signup.getOtp,
                requestData
            )
            // if (response.code === 200) {
            //     this.startCountdown()
            // }
            console.log(response)
        },

        async getSignUpVerification() {
            const requestData = {
                OTP: this.otp,
                sessionId: this.sessionId
            }
            const response = await sendRequest(
                Api.auth.signup.verification,
                requestData
            )
            if (response.code === 200) {

                this.$router.push({
                    path: path.auth.password,
                    query:{
                        phone : this.form.sdt,
                        sessionId: this.sessionId,
                    }
                })
                this.$store.commit('setSessionId', this.sessionId)
                this.$store.commit('setSdt', this.form.sdt)
            }
            console.log('veri', response)
        },
        resendOtpCode() {
            this.getOtp()
            this.startCountdown()
        },
        startCountdown() {
            this.countdownTime = 60
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval)
                this.countdownInterval = null
            }
            this.countdownInterval = setInterval(() => {
                this.countdownTime--
                if (this.countdownTime <= 0) {
                    clearInterval(this.countdownInterval)
                    this.countdownInterval = null
                }
            }, 1000)
        }
    },
    watch: {
        countdownTime(newCountdownTime) {
            if (newCountdownTime <= 0) {
                clearInterval(this.countdownInterval)
                this.countdownInterval = null
            }
        }
    }
}
</script>

<style>
.form-register label{
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 0;
}
.form-register .el-form-item__content{
    line-height: 40px;
}
</style>

<style scoped>

.bg {
    height: 100vh;
    background: #303030 url(@/assets/img/background.svg) center/cover no-repeat;
}
.container {
    padding-left: 0px;
    padding-right: 0px;
}
.layout-common {
    display: flex;
    justify-content: center;
}
.img-hr {
    object-fit: cover;
    width: 80%;
}

.layout-common h4 {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
}
.layout-common p {
    color: #ffffff;
    margin-bottom: 0;
    /*font-family: cursive, sans-serif;*/
}
.err {
    margin-left: 50px;
}
.err h6 {
    color: red;
    font-size: 16px;
    font-style: italic;
}
.bg-red {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn {
    padding: 13px 90px;
    border-radius: 10px;
}
.phone ::v-deep .el-input__inner {
    background-color: #fff;
    background-image: none;
    color: black;
    font-weight: 700;
    border: 0px;
}
.phone ::v-deep .el-input-group__prepend {
    background-color: #fff;
    color: black;
    font-weight: 700;

}
.otp ::v-deep .el-input__inner {
    background-color: #fff;
    border-radius: 12px;
    color: black;
    font-weight: 700;
}
</style>

<template>
    <div class="step7">
        <TitleSurvey :title="'Mức độ tập luyện hiện tại'" />
        <div class="container">
            <div
                class="row align-items-center justify-content-center text-center mt-5"
            >
                <div class="col-md-4">
                    <div
                        v-for="movement in list_movement"
                        :key="movement.idMovement"
                    >
                        <img
                            :src="movement.img"
                            class="img-gif"
                            v-if="
                                currentValueMovement.idMovement ===
                                movement.idMovement
                            "
                        />
                    </div>
                    <div class="form">
                        <div class="label">Bài tập</div>
                        <div class="layout-exercise">
                            <div
                                class="exercise exercise-text"
                                @click="nextValueMovement"
                            >
                                {{ currentValueMovement.label }}
                            </div>
                            <div
                                class="exercise exercise-icon"
                                @click="nextValueMovement"
                            >
                                <i class="el-icon-d-caret"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        class="form"
                        v-if="
                            this.currentValueMovement.idMovement === 5 ||
                            this.currentValueMovement.idMovement === 3
                        "
                    >
                        <div class="label">Mức độ (cái)</div>
                        <div>
                            <el-input-number
                                style="width: 100%"
                                v-model="repMinute"
                                controls-position="right"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                    <div
                        class="form"
                        v-if="this.currentValueMovement.idMovement === 4"
                    >
                        <div class="label">Mức độ thực hiện (phút)</div>
                        <div>
                            <el-input-number
                                style="width: 100%"
                                v-model="timeRep"
                                controls-position="right"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                    <div
                        class="form"
                        v-if="this.currentValueMovement.idMovement === 1"
                    >
                        <div class="label">Tổng số Rep thực hiện</div>
                        <div>
                            <el-input-number
                                style="width: 100%"
                                v-model="totalRep"
                                controls-position="right"
                                :min="0"
                            ></el-input-number>
                        </div>
                    </div>
                    <div
                        class="form row"
                        v-if="this.currentValueMovement.idMovement === 1"
                    >
                        <div class="col-sm-6">
                            <div class="label">Khối lượng thiết bị</div>
                            <div>
                                <el-input-number
                                    style="width: 100%"
                                    v-model="weightInRep"
                                    controls-position="right"
                                    :min="0"
                                ></el-input-number>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="label">RM</div>
                            <div>
                                <el-input-number
                                    style="width: 100%"
                                    v-model="rm"
                                    controls-position="right"
                                    :min="0"
                                ></el-input-number>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Tiếp tục"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'
import sendRequest from "@/services";
import Api from "@/constants/Api";
import {getFromLocalStorage} from "@/utils/localStorage";
import {getFromSessionStorage} from "@/utils/session";

export default {
    name: 'StepSeven',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function },
    data() {
        return {
            baitap: 'Chống đẩy',
            timeRep: '',
            repMinute: '',
            totalRep: '',
            weightInRep: '',
            rm: '',
            list_movement: [],
            selectedMovementIndex: 0
        }
    },
    computed: {
        currentValueMovement() {
            return this.list_movement[this.selectedMovementIndex]
        },
        movement() {
            return this.list_movement.map((movement) => {
                return {
                    idMovement: movement.idMovement,
                    totalRep:
                        movement.idMovement === 1 ? this.totalRep || null : null,
                    weightInRep:
                        movement.idMovement === 1
                            ? this.weightInRep || null
                            : null,
                    timeRep:
                        movement.idMovement === 4 ? this.timeRep || null : null,
                    repMinute:
                        movement.idMovement === 5
                            ? this.repMinute || null
                            : movement.idMovement === 3
                            ? this.repMinute || null
                            : null,
                    rm: movement.idMovement === 1 ? this.rm || null : null
                }
            })
        }
    },

    mounted() {

        this.getBaiTap()
    },

    methods: {
        async getBaiTap(){
            try {
                let user = getFromSessionStorage('user')

                const request_Params = {
                    'for_gender': user.userInfoIdentifing.gender,
                    page : 1,
                    size : 20
                }
                const response = await sendRequest(
                    Api.survey.getMovement,
                    null,
                    null,
                    request_Params
                )

                if (response.code === 200) {
                    console.log(response)
                    this.list_movement = response.item.content.map(item=>{
                        item.img = user.userInfoIdentifing.gender === 1 ? item.url_img_female : item.url_img_male
                        item.label = item.movement_name
                        item.idMovement = item.id
                        return item;
                    })

                    this.currentValueMovement()
                }
            } catch (error) {
                console.log(error)
            }


        },

        nextValueMovement() {
            if (this.selectedMovementIndex < this.list_movement.length - 1) {
                this.selectedMovementIndex += 1
            } else {
                this.selectedMovementIndex = 0
            }
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'movement',
                data: this.movement
            })
            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step7 {
    height: 100vh;
}

.img-gif {
    border-radius: 8px;
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.form {
    margin-top: 20px;
}
.label {
    text-align: left;
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 20px;
}
.layout-exercise {
    display: flex;
    align-items: center;
}
.exercise {
    cursor: pointer;
    color: #2e2b2e;
    font-weight: 700;
    background-color: #fff;
    background-image: none;

    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.exercise-text {
    border-radius: 4px 0px 0px 4px;
    width: 100%;
}
.exercise-icon {
    border-radius: 0px 4px 4px 0px;
}
::v-deep .el-input__inner {
    color: #2e2b2e;
    font-weight: 700;
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px;
        border-radius: 20px;
    }
}
</style>

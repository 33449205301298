<template>
    <div>
       <div class="container">
           <div class="pt-5 mb-5 layout-common text-center">
               <img src="@/assets/img/Asset 1 1.png" class="img" />

               <img src="@/assets/img/VFITNESS.svg" class="img" />
           </div>

           <div class="mb-5 layout-common">
               <img src="@/assets/img/hr.svg" class="img-hr" />
           </div>

           <div class="layout-common text-center text-white">
               <h4>Đăng nhập tài khoản</h4>
           </div>
          <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6">
                  <el-form class="formLogin" label-position="top" ref="loginForm" :model="loginData" :rules="loginRules" label-width="200px">
                      <el-form-item label="Số điện thoại" prop="phone">
                          <el-input v-model="loginData.phone" clearable placeholder="Số điện thoại"></el-input>
                      </el-form-item>
                      <el-form-item label="Mật khẩu" prop="password">
                          <el-input show-password v-model="loginData.password" type="password" clearable placeholder="Mật khẩu"></el-input>
                      </el-form-item>
                      <el-form-item>
                          <div class="text-center"><el-button type="primary" @click="login">Đăng nhập</el-button></div>
                      </el-form-item>
                  </el-form>
              </div>
              <div class="col-md-3"></div>
          </div>
       </div>
    </div>
</template>

<script>
import Api from '@/constants/Api'
import { path } from '@/router/path'
import sendRequest from '@/services'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'

export default {
    name: "PageLogin",
    data() {
        return {
            loginData: {
                phone: '',
                password: ''
            },
            loginRules: {
                phone: [
                    { required: true, message: 'Vui lòng nhập số điện thoại', trigger: 'blur' },
                    { pattern: /^[0-9]{10,12}$/, message: 'Số điện thoại phải có từ 10 đến 12 số', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Vui lòng nhập mật khẩu', trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        async actionLogin(){
            const requestData = {
                password: this.loginData.password,
                deviceId: (Date.now()).toString(),
                emailOrPhone : this.loginData.phone,
            }
            const response = await sendRequest(
                Api.auth.login,
                requestData
            )
            console.log(response)

            if (response.code === 200) {
                saveToSessionStorage('user', response)
                saveToSessionStorage('isLogin', true)

                this.$message({
                    message: 'Đăng nhập thành công',
                    type: 'success'
                });
                this.$router.push({ path: path.home.home }).then(() => {
                    window.location.reload();
                });

            }else{
                this.$alert(response.message, 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    center: true,
                    type: 'warning',
                    callback: action => {
                    }
                });
            }
        },

        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    // Nếu biểu mẫu hợp lệ, bạn có thể tiến hành xử lý đăng nhập ở đây
                    console.log('Đăng nhập thành công');
                    this.actionLogin()
                } else {
                    // Nếu biểu mẫu không hợp lệ, không cần làm gì
                }
            });
        }
    }
};
</script>

<style scoped>
.layout-common h4 {
    color: #ffffff;
    font-weight: 700;
    font-size: 32px;
}

</style>
<style>
.formLogin .el-input__inner{
    background-color: #fff;
    background-image: none;
    color: black;
    font-weight: 700;
    border: 0px;
}
label{
    margin: 0;
}
.formLogin label{
    margin: 0;
    font-weight: 700;
    color: #ffffff;
    line-height: 1;
}
</style>
<template>
    <div class="background">
        <div class="container">
            <div class="header">
                <div class="title">{{ title }}</div>
                <div>
                    <CustomButton
                        label="Quay lại"
                        size="medium"
                        type="text"
                        class="btn"
                        @click="handleBackDetail"
                    />
                </div>
            </div>
            <div class="detail">{{ detail }}</div>
            <div class="video">
                <video controls width="100%">
                    <source :src="url" type="video/mp4" />
                </video>
            </div>
        </div>
        <!-- <div class="container">
            <div class="sub-title">Các video hướng dẫn</div>
        </div>
        <div class="list-video">
            <div>
                <img
                    src="@/assets/img/left-arrow.svg"
                    class="img-arrow"
                    @click="showNextListFree"
                />
            </div>
            <div class="container slick">
                <VueSlickCarousel v-bind="settings" ref="carouselListFree">
                    <div v-for="item in fakeData" :key="item.id" class="detail">
                        <img :src="item.image" class="img" />
                        <div class="content">
                            <div class="exercise-title">
                                {{ item.title }}
                            </div>
                            <div class="exercise-description">
                                {{ item.description }}
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
            <div>
                <img
                    src="@/assets/img/right-arrow.svg"
                    class="img-arrow"
                    @click="showNextListFree"
                />
            </div>
        </div> -->
    </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import home1 from '@/assets/img/home1.svg'
import home2 from '@/assets/img/home2.svg'
import home3 from '@/assets/img/home3.svg'
import CustomButton from '@/components/CustomButton.vue'
import { getFromSessionStorage } from '@/utils/session'
import { path } from '@/router/path'

export default {
    name: 'DeTail',
    components: { CustomButton },

    data() {
        return {
            product: {},
            settings: {
                dots: true,
                arrows: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 0,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            },
            title: '',
            detail: '',
            url: null
        }
    },
    methods: {
        showNextListFree() {
            this.$refs.carouselListFree.next()
        },
        showNextListPay() {
            this.$refs.carouselListPay.next()
        },
        showNextListGirl() {
            this.$refs.carouselListGirl.next()
        },
        handleBackDetail() {
            this.$router.push(path.home.home)
        }
    },
    created() {
        const detailItem = getFromSessionStorage('detail')
        if (detailItem) {
            this.title = detailItem.title
            this.detail = detailItem.detail
            this.url = detailItem.url
        }
    }
}
</script>

<style scoped>
.background {
    background: #191718 url(@/assets/img/detail.svg) center/cover no-repeat;

    background-size: cover;
    background-color: #191718;
    height: 100vh;
}

.header {
    display: flex;
    align-items: center;

    justify-content: space-between;
    padding-top: 20px;
}
.title {
    color: #ffffff;
    font-weight: 900;
    font-size: 36px;
}
.btn {
    padding: 9px 40px;
    border-radius: 8px;
    background: #f5f7f7;
    color: #303030;
}
.video {
    margin-top: 20px;
}
.list-video {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-pay {
    padding-top: 50px;
}
.sub-title {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
}
.slick {
    margin: 0px 30px 0px 30px;
}
.navigate-detail {
    cursor: pointer;
}
.detail {
    padding: 0px 5px 0px 5px;
    color: #ffffff;
    font-size: 18px;
}
.content {
    background-color: #ffffff;

    padding: 20px 10px 20px 10px;
    border-radius: 0px 0px 24px 24px;
}
.image-container,
.icon-container {
    position: relative;
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    image-rendering: pixelated;
    border-radius: 24px 24px 0px 0px;
}
.img-video {
    width: 100%;
    object-fit: cover;
}
.icon {
    width: 100%;
    height: 200px;
    object-fit: cover;
    image-rendering: pixelated;
    border-radius: 24px 24px 0px 0px;
    position: absolute;
    top: -200px;
    left: 0;
}
.exercise-layout {
    display: flex;
    align-items: center;
}
.exercise-title {
    padding: 0px 10px 0px 0px;
    color: #000000;
    font-size: 18px;
    font-weight: 800;
}
.exercise-description {
    margin-top: 5px;
    color: #d2232e;
    font-size: 14px;
    font-weight: 700;
}
.exercise-description-girl {
    margin-top: 5px;
    color: #d2232e;
    font-size: 14px;
    font-weight: 700;
}
.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #d2232e;
    border-radius: 50%;
    margin-right: 5px;
}
.img-arrow {
    cursor: pointer;
    height: 40px;
    object-fit: cover;
}
::v-deep .slick-dots li.slick-active button:before {
    margin-top: 10px;
    color: red;
}
::v-deep .slick-dots li button:before {
    margin-top: 10px;
    font-size: 14px;
}

@media (max-width: 767px) {
    .layout-video {
        padding: 20px 20px 20px 20px;
    }
    .img-video {
        width: 100%;
    }
}
</style>

<template>
    <div class="title">
        {{ title }} <span class="span">{{ sub }}</span>
    </div>
</template>

<script>
export default {
    name: 'TitleComponent',
    props: {
        title: {
            type: String
        },
        sub: {
            type: String
        }
    }
}
</script>

<style scoped>
.title {
    padding: 30px 0 10px 0;
    text-transform: uppercase;
    color: #191718;
    font-weight: 900;
    font-size: 26px;
}

.title .span {
    font-weight: 900;
    color: #0276fa;
}
@media (max-width: 767px) {
    .title {
        font-size: 24px;
    }
}
</style>

<template>
    <div class="step3">
        <TitleSurvey :title="'Số đo cơ thể'" />
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-sm-1"></div>
                <div class="col-md-5">
                    <div class="form">
                        <div class="col-sm-5 detail">Chiều dài vai (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="shoulderMeasurement"
                                :min="20"
                                :max="60"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-sm-5 detail">Chu vi tay (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="armMeasurements"
                                :min="10"
                                :max="60"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-sm-5 detail">Vòng đo ngực (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="chestMeasurement"
                                :min="50"
                                :max="200"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-sm-5 detail">Vòng đo eo (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="waistMeasurement"
                                :min="20"
                                :max="200"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-sm-5 detail">Vòng đo mông (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="buttMeasurement"
                                :min="50"
                                :max="200"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                    <div class="form">
                        <div class="col-sm-5 detail">Vòng đo chân (cm)</div>
                        <div class="col-sm-7">
                            <ElInputNumber
                                v-model="legMeasurement"
                                :min="10"
                                :max="60"
                                :width="100"
                                :widthUnit="'%'"
                            >
                            </ElInputNumber>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 text-center">
                    <img
                        src="@/assets/img/step3/img1.svg"
                        style="height: 500px"
                    />
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Tiếp tục"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'

import ElInputNumber from '@/components/ElInputNumber.vue'
import TitleSurvey from '@/components/TitleSurvey.vue'

export default {
    name: 'StepThree',
    components: { CustomButton, ElInputNumber, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            shoulderMeasurement: null,
            armMeasurements: null,
            chestMeasurement: null,
            waistMeasurement: null,
            buttMeasurement: null,
            legMeasurement: null
        }
    },
    methods: {
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$store.commit('updateSurveyData', {
                key: 'shoulderMeasurement',
                data: this.shoulderMeasurement
            })
            this.$store.commit('updateSurveyData', {
                key: 'armMeasurements',
                data: this.armMeasurements
            })
            this.$store.commit('updateSurveyData', {
                key: 'chestMeasurement',
                data: this.chestMeasurement
            })
            this.$store.commit('updateSurveyData', {
                key: 'waistMeasurement',
                data: this.waistMeasurement
            })
            this.$store.commit('updateSurveyData', {
                key: 'buttMeasurement',
                data: this.buttMeasurement
            })
            this.$store.commit('updateSurveyData', {
                key: 'legMeasurement',
                data: this.legMeasurement
            })

            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step3 {
    /* height: 100vh; */
}

.detail {
    font-weight: 700;
}
.form {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 14px;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}
.btn {
    padding: 13px 120px 13px 120px;
    border-radius: 20px;
    text-transform: uppercase;
}
.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
}
.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
}
::v-deep .el-input__inner {
    color: #0276fa;
    font-weight: 800;
    font-size: 20px;
    height: 40px;
}
@media (max-width: 768px) {
    .btn {
        padding: 13px 50px 13px 50px;
    }
}
</style>

<template>
    <div class="step0">
        <img src="@/assets/img/step0/Frame.svg" class="img" />

        <div class="title">
            <div>Hãy trả lời vài câu hỏi</div>
            <div>để chúng tôi có thể hiểu bạn hơn nhé !</div>
        </div>
        <div class="layout-btn">
            <CustomButton
                label="Khám phá ngay"
                size="medium"
                type="text"
                class="btn"
                @click="handleNextStep1"
            />
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'

export default {
    name: 'StepO',
    components: { CustomButton },
    props: { handleNextStep: Function },
    data() {
        return {}
    },
    methods: {
        handleNextStep1() {
            this.$emit('handleNextStep')
        }
    }
}
</script>

<style scoped>
.step0 {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title {
    padding: 30px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
}
.layout-btn {
    padding-bottom: 70px;
}
.btn {
    background-color: #0276fa;
    color: #ffffff;
    padding: 15px 70px 15px 70px;
    border-radius: 12px;
    text-transform: uppercase;
}
.img {
    object-fit: cover;
    width: 100%;
    max-width: 55vh;
}
@media (max-width: 768px) {
    .main .img {
        width: 90%;
    }
}
</style>

<template>
    <div>
        <el-input
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            :clearable="clearable"
            :size="size"
            :type="type"
            @input="updateValue"
            :show-password="showPassword"
            v-bind="$attrs"
        >
        </el-input>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
        value: String || Number,
        disabled: Boolean,
        clearable: Boolean,
        size: String,
        type: String,
        showPassword: Boolean
    },
    methods: {
        updateValue(newValue) {
            const trimmedValue = newValue.trim()
            this.$emit('input', trimmedValue)
        }
    }
}
</script>

<template>
    <div class="payment">
        <div class="container">
            <div class="title">Thanh toán</div>
            <div class="hr">
                <hr />
            </div>

            <div class="content">
                Chuyển khoản ngân hàng
                <img :src="imgSrc" class="img-atm" />
            </div>

            <div class="row align-items-center">
                <div class="col-md-3">
                    <img width="100%" :src="dataPayment.qr_code || imgQR" />
                </div>
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-6"><div class="info-qr"><strong>Tên ngân hàng</strong></div></div>
                        <div class="col-md-6">
                            <div class="info-qr text-right">
                                {{dataPayment.rec_bank}}
                            </div>
                        </div>


                        <div class="col-md-6"><div class="info-qr"><strong>Số tài khoản</strong></div></div>
                        <div class="col-md-6">
                            <div class="info-qr text-right">
                                {{dataPayment.rec_acct_id}}
                            </div>
                        </div>

                        <div class="col-md-6"><div class="info-qr"><strong>Tên tài khoản</strong></div></div>
                        <div class="col-md-6">
                            <div class="info-qr text-right">
                                {{dataPayment.rec_acct_name}}
                            </div>
                        </div>

                        <div class="col-md-6"><div class="info-qr"><strong>Số tiền</strong></div></div>
                        <div class="col-md-6">
                            <div class="info-qr text-right">
                                {{dataPayment.price || 0}}VNĐ
                            </div>
                        </div>

                        <div class="col-md-6"><div class="info-qr"><strong>Nội dung chuyển khoản</strong></div></div>
                        <div class="col-md-6">
                            <div class="info-qr text-right">
                                {{dataPayment.remark || ''}}VNĐ
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="row align-items-center mt-5">


            </div>

            <div class="text-center">
                <div class="info-qr" style="color: red">Hệ thống sẽ kích hoạt gói tập ngay kể từ khi nhận được thông tin chuyển khoản</div>

                <div class="info-qr"><label><input v-model="checkbox" class="checkbox" type="checkbox"> Đồng ý với chính sách thanh toán</label></div>
            </div>
            <div class="text-center mb-5">
<!--                <CustomButton-->
<!--                    label="Quay lại"-->
<!--                    size="medium"-->
<!--                    type="text"-->
<!--                    class="btn-white custom-btn"-->
<!--                />-->

                <CustomButton
                    label=" Xác nhận thanh toán"
                    size="medium"
                    type="text"
                    class="btn-red custom-btn"
                    @click="payment"
                />
            </div>
        </div>
    </div>
</template>
<script>
import payment1 from '@/assets/img/payment1.svg'
import payment9 from '@/assets/img/payment9.svg'
import CustomButton from '@/components/CustomButton.vue'
import { mapState,mapMutations } from 'vuex'
import {getFromSessionStorage} from "@/utils/session";
import Api from '@/constants/Api'
import sendRequest from '@/services'

export default {
    name: 'QR',
    components: { CustomButton },
    data() {
        return {
            checkbox : false,
            imgSrc: payment1,
            imgQR: payment9,
            radio: '',
            dataPayment : {},
        }
    },
    methods: {
        async payment(){
            if (!this.checkbox){
                this.$alert('Vui lòng đồng ý với các điều khoản thanh toán của chúng tôi', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    center: true,
                    type: 'warning',
                    callback: action => {
                    }
                });
                return
            }
            try {
                let user = getFromSessionStorage('user')
                let token = user.token
                const request_Data = {

                    "paymentId": 1,
                    "status": "string",
                    "transId": this.dataPayment.trans_id,
                    "transInAppId": null
                }
                const response = await sendRequest(
                    Api.payment.payment,
                    request_Data,
                    {
                        token: token
                    }
                )

                if (response.code === 200) {
                    console.log(response)
                    this.$alert(response.item.message || 'Hệ thống đã tiếp nhận yêu cầu thanh toán.Vui lòng đợi CSKH kiểm tra', 'Thông báo', {
                        confirmButtonText: 'Đóng',
                        center: true,
                        type: 'success',
                        callback: action => {
                        }
                    });
                }else{
                    this.$alert(response.item.message || 'Hệ thống chưa xử lý được yêu cầu thanh toán.Vui lòng liên hệ CSKH kiểm tra', 'Thông báo', {
                        confirmButtonText: 'Đóng',
                        center: true,
                        type: 'warning',
                        callback: action => {
                        }
                    });
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getQR(){

            try {
                let user = getFromSessionStorage('user')
                let token = user.token
                const request_Data = {


                    "packageTimeId": this.planSelect.packageTimeId,
                    "paymentId": 1,
                    "pendingOldPlan": true,
                    "planId": this.planSelect.id,
                    "planScheduleId":  this.planSelect.planScheduleInfoId,
                    "voucherCodes": []
                }
                const response = await sendRequest(
                    Api.payment.getQr,
                    request_Data,
                    {
                        token: token
                    }
                )

                if (response.code === 200) {
                    console.log(response)
                    this.dataPayment = response.item
                }
            } catch (error) {
                console.log(error)
            }
        },

    },
    computed: {
        ...mapState(['planSelect'])
    },
    mounted() {

        if (!this.planSelect){
            this.$router.push('/plan-detail')
        }else {
            console.log('get QR')
            this.getQR()
        }
    }
}
</script>

<style scoped>
.payment {
    background: #303030 url(@/assets/img/background.svg) center/cover no-repeat;

    background-size: cover;
}
.title {
    font-weight: 900;
    color: #ce0e2d;
    font-size: 36px;
    padding-top: 30px;
}
.img-atm {
    padding: 0px 0px 22px 15px;
}

.hr {
    margin-top: 30px;
}
.hr hr {
    margin: 0;
    border: 0.1px solid #f5f7f78f;
    width: 75%;
}
.content {
    padding: 30px 0px 20px 0px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 800;
}
.info-qr {
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 16px;
}
.btn-red {
    background-color: #d2232e;
    color: #ffffff;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}

.custom-btn {
    padding: 13px 63px;
    border-radius: 20px;
}

.layout-btn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
}
.title-radio-2 {
    padding: 0;
    color: #ffffff;
    font-weight: 900;
    font-size: 30px;
}
.title-radio-1 {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
}
.btn-change {
    padding: 10px 50px;
    border-radius: 20px;
}
.btn-no-background {
    color: #ffffff;
    border: 1px solid #d2232e;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #ce0e2d;
    background: #ce0e2d;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #ffffff;
}
</style>

<template>
    <div class="step14 mt-5">
        <TitleSurvey :title="(planSelect.name).toUpperCase()" />
        <div class="container">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-4">
                    <img src="@/assets/img/step13//img1.svg" class="img1" />
                    <img src="@/assets/img/step13//img2.svg" class="img2" />
                </div>

                <div class="col-sm-6">
                    <h4 class="h4">Giới thiệu</h4>
                    <p class="p">
                        {{planSelect.description}}
                    </p>

                    <div class="mt-3">
                        <h4 class="h4">Chọn gói mua</h4>
                    </div>

                    <div>
                        <div
                                class="row mt-3 d-flex align-items-center   "
                        >
                            <div
                                    v-for="(item, index) in plan_schedules"
                                    :key="index"
                                    class="btn-white day m-2"
                                    :class="{
                        selectedDay: planScheduleInfoId === item.package_time_id
                    }"
                                    @click="handleDayClick(item.package_time_id)"
                            >
                                {{ item.displayName }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h4 class="h4">Chi tiết thanh toán</h4>
                        <div class="p">Giá sản phẩm: {{dataPlan.price}}</div>
                        <div class="p">Giảm giá: {{dataPlan.discount ? ('-'+dataPlan.discount) : '0'}}</div>
                    </div>
                    <div class="mt-3">
                        <div style="display: flex;">
                            <h4 class="h4">Tổng thanh toán</h4>
                            <strong style="color: red;margin-left: 10px">{{dataPlan.cost_origin}}</strong>
                        </div>


                    </div>
                </div>
                <div class="col-sm-1"></div>
            </div>

        </div>

        <div class="layout-btn">
            <CustomButton
                    label="Quay lại"
                    size="medium"
                    type="text"
                    class="btn btn-white"
                    @click="BackStep"
            />
            <CustomButton
                    label="THANH TOÁN"
                    size="medium"
                    type="text"
                    class="btn btn-yellow"
                    @click="handleConfirmPayFinal"
            />
        </div>
        <ElDialog
                :title="'Thông báo'"
                :message="messageDialog"
                :visible.sync="dialogVisible"
                :textBtn="'Thực hiện lại'"
                :showButton="false"
        />
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import TitleSurvey from '@/components/TitleSurvey.vue'
import { getFromLocalStorage } from '@/utils/localStorage'
import { MessageBox } from 'element-ui'
import ElDialog from '@/components/ElDialog.vue'
import {getFromSessionStorage} from "@/utils/session";
import { mapState,mapMutations } from 'vuex'

export default {
    name: 'StepFourteen',
    components: { CustomButton, TitleSurvey, ElDialog },
    props: { handleNextStep: Function, handleBackStep: Function },
    computed: {
        ...mapState(['planSelect'])
    },
    data() {
        return {
            messageDialog: '',
            dialogVisible: false,
            trainingWeeks: [
                {
                    title: 'TUẦN 1',
                    description: 'Tuần 1'
                },
                { title: 'TUẦN 2', description: 'Tuần 2' },
                { title: 'TUẦN 3', description: 'Tuần 3' }
            ],
            days: [
                { id: 1, content: 'Thứ 2 - Thứ 3 - Thứ 4' },
                { id: 2, content: 'Thứ 2 - Thứ 3 - Thứ 4' },
                { id: 3, content: 'Thứ 2 - Thứ 3 - Thứ 4' }
            ],
            planScheduleInfoId: null,
            plan_schedules: [],
            plan_id: '',
            dataPlan: {},
            user : {},
        }
    },
    beforeMount() {
        this.user = getFromSessionStorage('user')
    },
    mounted() {
        if (!this.planSelect){
            this.$router.push('/plan-detail')
        }else {
            this.suggestPlan()
        }

    },
    methods: {
        ...mapMutations({
            setPlanSelect : 'setPlanSelect',
        }),

        async suggestPlan() {
            try {
                let user = getFromSessionStorage('user')
                let token = user.token
                const request_Params = {
                    paymentId : 1,
                    planId : this.planSelect.id
                }

                const response = await sendRequest(
                    Api.survey.planPackage,
                    null,
                    {
                        token: token
                    },
                    request_Params
                )
                if (response.code === 200) {

                    if (response.item && response.item.package_default){
                        this.plan_schedules = response.item.package_default
                        if (this.plan_schedules.length){
                            let item = this.plan_schedules[0]
                            this.planScheduleInfoId = item.package_time_id
                            this.getPricePackage()
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleConfirmPay() {

            try {
                let storeSurvey = getFromLocalStorage('surveyData')
                const request_Data = {
                    item: {
                        phoneNumber: storeSurvey.phoneNumber,
                        plan_id: this.plan_id,
                        planScheduleInfoId: this.planScheduleInfoId
                    }
                }
                const response = await sendRequest(
                    Api.survey.confirmPay,
                    request_Data,
                    null
                )
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        },
        async handleConfirmPayFinal() {

            let planSelect = JSON.parse(JSON.stringify(this.planSelect));

            planSelect.packageTimeId = this.planScheduleInfoId

            this.setPlanSelect(planSelect)

            this.$router.push('/pay')

            // if (this.planScheduleInfoId === null) {
            //     alert('Vui lòng chọn ngày tập')
            //     return
            // }
            // await this.handleConfirmPay()
            // try {
            //     let storeSurvey = getFromLocalStorage('surveyData')
            //     const request_Data = {
            //         paid_success: true,
            //         phone_number: storeSurvey.phoneNumber
            //     }
            //     const response = await sendRequest(
            //         Api.survey.confirmPayFinal,
            //         request_Data,
            //         null
            //     )
            //     this.dialogVisible = true
            //     if (response.code === 200) {
            //         this.messageDialog = response.message
            //     } else {
            //         this.messageDialog = response.message
            //     }
            // } catch (error) {
            //     console.log(error)
            // }
        },
        async getPricePackage() {
            // if (this.planScheduleInfoId === null) {
            //     alert('Vui lòng chọn ngày tập')
            //     return
            // }

            try {
                let user = getFromSessionStorage('user')
                let token = user.token
                const request_Data = {

                    "packageTimeId": this.planScheduleInfoId,
                    "paymentId": 1,
                    "pendingOldPlan": true,
                    "planId": this.planSelect.id,
                    "planScheduleId":  this.planSelect.planScheduleInfoId,
                    "voucherCodes": []

                }
                const response = await sendRequest(
                    Api.survey.getPricePackage,
                    request_Data,
                    {
                        token:token
                    }
                )

                if (response.code === 200) {
                    this.dataPlan = response.item
                }
            } catch (error) {
                console.log(error)
            }
        },
        handleDayClick(id) {
            this.planScheduleInfoId = id
            console.log(this.planScheduleInfoId)
            this.getPricePackage()
        },
        BackStep() {
            this.$router.push('/plan-detail')

        }
    }
}
</script>

<style scoped>
.step14 {
    height: 100vh;
}
.img1 {
    width: 100%;
    object-fit: cover;
    display: block;
    position: relative;
}
.img2 {
    position: absolute;
    left: 45%;
    top: 90%;
    object-fit: cover;
    width: 50%;
}

.h4 {
    color: #fcf9f4;
    letter-spacing: 3px;
    font-weight: 900;
}
.p {
    color: #fcf9f4;
    font-weight: 600;
    font-size: 14px;
}
.overview,
.training-route {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
h4 {
    color: #fcf9f4;
    font-size: 18px;
    font-weight: 900;
}
.img-overview {
    object-fit: cover;
    height: 15px;
}
.title-overview {
    margin-left: 15px;
    color: #fcf9f4;
}

.img-training-route {
    height: 10px;
    object-fit: cover;
}
.title-training-route {
    margin-left: 15px;
    color: #fcf9f4;
    font-size: 15px;
    font-weight: 600;
}
.title-training-route span {
    color: #fcf9f4;
    font-weight: 500;
    font-size: 13px;
    margin-left: 15px;
}
.vertical-line {
    border: 1px solid #fcf9f4;
    width: 20%;
}
.day {
    border-radius: 16px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
}
.selectedDay {
    background-color: #0276fa !important;
    color: #ffffff !important;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-yellow {
    background-color: #fdb209;
    color: #2e2b2e;
    font-weight: 700;
}
::v-deep .el-button.is-disabled.el-button--text {
    background-color: #fdb209;
    color: #2e2b2e;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 8px;
}
</style>

<template>
    <div class="mixed-input">
        <el-input
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            :clearable="clearable"
            :size="size"
            :type="inputType"
            v-bind="$attrs"
            @input="updateValue"
        >
            <template slot="append">
                <img
                    v-if="inputType === 'password'"
                    src="@/assets/img/pw1.svg"
                    class="img-pw"
                    @click="togglePasswordType"
                />
                <img
                    v-if="inputType === 'text'"
                    src="@/assets/img/pw2.svg"
                    class="img-pw"
                    @click="togglePasswordType"
                />
            </template>
        </el-input>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
        value: String || Number,
        disabled: Boolean,
        clearable: Boolean,

        size: String,
        type: String
    },
    data() {
        return {
            inputType: 'password'
        }
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue) // Kích hoạt sự kiện "input" với giá trị mới
        },
        togglePasswordType() {
            this.inputType = this.inputType === 'password' ? 'text' : 'password'
        }
    }
}
</script>

<style scoped>
.mixed-input ::v-deep .el-input__inner {
    background-color: #fff;
    background-image: none;
    color: black;
    font-weight: 700;
    border: 0px;
    border-radius: 12px 0px 0px 12px;
}

.mixed-input ::v-deep .el-input-group__append {
    background-color: #fff;
    color: black;
    font-weight: 700;
    border-radius: 0px 12px 12px 0px;
}
.img-pw {
    cursor: pointer;
}
</style>

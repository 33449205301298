<template>
    <div>
        <div class="title">
            <div class="padding">
                <img src="@/assets/img/Asset 1 1.png" class="img" />
            </div>

            <div><img src="@/assets/img/VFITNESS.svg" class="img" /></div>
        </div>
        <div class="row justify-content-center">
            <div class="icon" @click="handleBackStep" v-show="currentStep > 1">
                <i class="el-icon-arrow-left" :class="'size-icon'"></i>
            </div>

            <div class="col-md-8 mt-2">
                <el-steps :active="currentStep" align-center>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                    <el-step class="style-step"></el-step>
                </el-steps>
            </div>
            <div class="col-md-1 mt-1 count-step" v-show="currentStep > 1">
                {{ currentStep - 1 }}/{{ totalSteps - 1 }}
            </div>
        </div>
        <div class="layout-step" v-show="currentStep === 1">
            <Step0 @handleNextStep="handleNextStep" />
        </div>
        <div class="layout-step" v-show="currentStep === 2">
            <Step1
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 3">
            <Step2
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 4">
            <Step3
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 5">
            <Step4
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 6">
            <Step5
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 7">
            <Step6
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 8">
            <Step7
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 9">
            <Step8
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 10">
            <Step9
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div class="layout-step" v-show="currentStep === 11">
            <!-- <Step10
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            /> -->
            <Step11
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div v-show="currentStep === 12">
            <!-- <Step11
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            /> -->
        </div>
        <!-- <div v-show="currentStep === 13">
            <Step12
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div> -->
        <!-- <div v-show="currentStep === 14">
            <Step13
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div>
        <div v-show="currentStep === 15">
            <Step14
                @handleBackStep="handleBackStep"
                @handleNextStep="handleNextStep"
            />
        </div> -->
    </div>
</template>
<script>
import Step0 from './Step0.vue'
// import Step1 from './Step1.vue'
import Step1 from './Step2.vue'
import Step2 from './Step3.vue'
import Step3 from './Step4.vue'
import Step4 from './Step5.vue'
import Step5 from './Step6.vue'
import Step6 from './Step7.vue'
import Step7 from './Step8.vue'
import Step8 from './Step9.vue'
import Step9 from './Step12.vue'
import Step10 from './Step11.vue'
import Step11 from './Step12.vue'
import Step12 from './Step13.vue'
import Step13 from './Step14.vue'
import { getFromSessionStorage, saveToSessionStorage } from '@/utils/session'
export default {
    name: 'SurVey',
    components: {
        Step0,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        Step7,
        Step8,
        Step9,
        // Step10,
        Step11
        // Step12,
        // Step13,
        // Step14
    },
    data() {
        return { currentStep: 1, totalSteps: 0 }
    },
    created() {
        const storedStep = getFromSessionStorage('currentStep')
        this.currentStep = storedStep ? parseInt(storedStep, 10) : 1

        this.totalSteps = Object.keys(this.$options.components).filter(
            (componentName) => componentName.startsWith('Step')
        ).length
    },
    methods: {
        handleNextStep() {
            if (this.currentStep < this.totalSteps) {
                this.currentStep++
            }
            saveToSessionStorage('currentStep', this.currentStep)
        },
        handleBackStep() {
            if (1 < this.currentStep) {
                this.currentStep--
            }
            saveToSessionStorage('currentStep', this.currentStep)
        }
    }
}
</script>

<style scoped>
.title {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title .padding {
    padding: 15px 15px 15px 0px;
}
.title .img {
    height: 30px;
    object-fit: cover;
    cursor: pointer;
}
.step {
    padding: 0px 0px 30px 0px;
}

.layout-step {
    margin-top: 20px;
}
.icon {
    padding: 20px;
    background-color: #0276fa;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
}
.size-icon {
    font-size: 20px;
}
.count-step {
    color: #ffffff;
}
.style-step {
    margin-right: 20px !important;
}
::v-deep .el-step__icon {
    display: none;
}
/* ::v-deep .el-step__head.is-finish {
    color: #0276fa;
    border-color: #0276fa;
} */
::v-deep .el-step.is-horizontal .el-step__line {
    height: 5px;
}
::v-deep .el-step.is-horizontal .is-center {
    margin-right: 20px;
}
::v-deep .el-step__line-inner {
    background-color: #0276fa;
    height: 5px;
}
@media (max-width: 767px) {
    .row {
        flex-direction: column; /* Chuyển layout thành dọc */
    }

    .icon {
        display: none;
    }

    .col-md-8 {
        width: 100%; /* Bước tiếp chiếm toàn bộ chiều rộng */
    }

    .count-step {
        text-align: center; /* Canh giữa số bước */
        margin-top: 10px; /* Khoảng cách giữa số bước và bước tiếp */
    }

    .el-steps {
        text-align: center; /* Canh giữa các bước */
    }
}
</style>

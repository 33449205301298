<template>
    <div class="main-wrapper">
        <Header v-if="!navigation" />

        <div class="page">
            <router-view />
        </div>
        <Footer v-if="!navigation" />
    </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
    name: 'app',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            navigation: null
        }
    },

    methods: {
        checkRoute() {
            if (this.$route.name === 'Detail') {
                this.navigation = true
                return
            } else {
                this.navigation = false
            }
        }
    },

    watch: {
        $route() {
            this.checkRoute()
        }
    },
    created() {
        this.checkRoute()
    }
}
</script>
<style>
.main-wrapper {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}
.page {
    flex: 1;

    background-color: #303030;
}
</style>

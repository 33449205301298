export const saveToSessionStorage = (key, data) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(data))
    } catch (error) {
        console.error('Error saving to sessionStorage:', error)
    }
}

// Lấy dữ liệu từ sessionStorage
export const getFromSessionStorage = (key) => {
    try {
        const storedData = sessionStorage.getItem(key)
        return storedData ? JSON.parse(storedData) : null
    } catch (error) {
        console.error('Error getting from sessionStorage:', error)
        return null
    }
}
export const deleteSessionStorage = (key) =>
{
    try {
        sessionStorage.removeItem(key)
    } catch (e){
        console.error('Error remove session: ', e)
    }
}

<template>
    <div class="step12 mt-5">
        <TitleSurvey :title="'Kết quả phân tích'" />

        <div class="row justify-content-center text-center">
            <div class="col-sm-3 layout-row">
                <div>
                    <img
                        src="@/assets/img/step12/img9.svg"
                        class="avatar"
                        v-if="user.userInfoIdentifing.gender === 0"
                    />
                    <img
                        src="@/assets/img/step12/img10.svg"
                        class="avatar"
                        v-if="user.userInfoIdentifing.gender === 1"
                    />
                </div>
                <div class="label mt-3">{{ user.userInfoIdentifing.displayName }}</div>
            </div>
            <hr class="vertical-line" />
            <div class="col-sm-3 mt-4">
                <div class="title">Tổng kết số liệu cơ thể</div>
                <div class="box mt-5">
                    <div class="bmi-rm">
                        <div class="bmi">
                            <div class="title-bmi">BMI hiện tại</div>
                            <div class="value-bmi">{{ surveyData.bmi }} BMI</div>
                        </div>
                        <div class="rm">
                            <div class="title-rm">RM hiện tại</div>
                            <div class="value-rm">{{ surveyData.bmi }} RM</div>
                        </div>
                    </div>
                    <div class="weight mt-2">
                        <div class="title-weight">Thiếu cân</div>
                        <div class="title-weight">Thừa cân</div>
                    </div>
                    <div class="img-weight mt-1">
                        <img src="@/assets/img/step12//img2.svg" class="img2" />
                        <img src="@/assets/img/step12//img1.svg" class="img1" />
                    </div>
                </div>
                <div class="row d-flex align-items-center">
                    <div class="col-sm-6">
                        <div class="box mt-3 text-left">
                            <div class="key">Tuổi</div>
                            <div class="value">

                                {{ calculateAge(user.userInfoIdentifing.birthDay) }}
                            </div>
                        </div>

                        <div class="box mt-3 text-left">
                            <div class="key">Cân nặng</div>
                            <div class="value">{{ surveyData.weight }} kg</div>
                        </div>
                        <div class="box mt-3 text-left">
                            <div class="key">Trao đổi chất (ngày)</div>
                            <div class="value">2002 Calories</div>
                        </div>
                        <!-- <div class="box mt-3 text-left">
                                <div class="key">Mục tiêu</div>
                                <div class="value">- 20kg</div>
                            </div> -->
                    </div>
                    <div class="col-sm-6">
                        <img src="@/assets/img/step12//img3.svg" class="img3" />
                    </div>
                </div>
            </div>
            <hr class="vertical-line" />

            <hr class="vertical-line" />
            <div class="col-sm-3 mt-4">
                <div class="title">Kế hoạch GIẢM CÂN trong 4 tuần</div>
                <div class="box mt-5">
                    <div class="row">
                        <div class="col-sm-7">
                            <div class="plan">
                                <div class="icon">
                                    <img src="@/assets/img/step12//img4.svg" />
                                </div>
                                <div class="content-plan">
                                    <div class="key-plan">Mục tiêu</div>
                                    <div class="value-plan">
                                        {{ getNameById(surveyData.target) }}
                                    </div>
                                </div>
                            </div>
                            <div class="plan">
                                <div class="icon">
                                    <img src="@/assets/img/step12//img5.svg" />
                                </div>
                                <div class="content-plan">
                                    <div class="key-plan">Nơi tập luyện</div>
                                    <div class="value-plan">
                                        <span
                                            v-if="surveyData.practicePlace === 1"
                                            >Tại nhà</span
                                        >
                                        <span v-else>Phòng tập</span>
                                    </div>
                                </div>
                            </div>
                            <div class="plan">
                                <div class="icon">
                                    <img src="@/assets/img/step12//img6.svg" />
                                </div>
                                <div class="content-plan">
                                    <div class="key-plan">
                                        Thời gian tập luyện
                                    </div>
                                    <div class="value-plan">
                                        {{
                                            getNameDaysPerWeek(
                                                surveyData.optionDaysPerWeek
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <img src="@/assets/img/step12//img7.svg" />
                        </div>
                    </div>
                </div>
                <div class="box mt-3">
                    <img src="@/assets/img/step12//img8.svg" />
                    <div class="day">
                        <div class="key-day">Hiện tại</div>
                        <div class="value-day">Sau 4 tuần</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="Xem kế hoạch"
                size="medium"
                type="text"
                class="btn btn-blue"
                @click="NextStep"
            />
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import Api from '@/constants/Api'
import sendRequest from '@/services'
import { getFromLocalStorage } from '@/utils/localStorage'
import TitleSurvey from '@/components/TitleSurvey.vue'
import moment from 'moment'
import {getFromSessionStorage} from "@/utils/session";

export default {
    name: 'StepTwelve',
    components: { CustomButton, TitleSurvey },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            circleUrl:
                'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            surveyData: {},
            user : {},
            list_target: [],
            list_option_days_per_week: [
                { id: 1, label: '3-4 buổi trên tuần' },
                { id: 2, label: '3-4-5 buổi trên tuần' },
                { id: 3, label: '4-5 buổi trên tuần' }
            ]
        }
    },
    beforeMount() {
        this.user = getFromSessionStorage('user')
    },
    mounted() {

        this.suggestSurvey()
        this.getListTarget()
    },

    methods: {
        calculateAge(birthdayString) {
// Tách ngày, tháng và năm từ chuỗi
            var parts = birthdayString.split('/');
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10) - 1; // Trừ đi 1 vì tháng trong JavaScript bắt đầu từ 0
            var year = parseInt(parts[2], 10);

// Tạo đối tượng Date từ ngày sinh
            var birthday = new Date(year, month, day);

// Tính tuổi
            var today = new Date();
            var age = today.getFullYear() - birthday.getFullYear();
            var monthDiff = today.getMonth() - birthday.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
                age--;
            }

            return age;
        },
        getNameDaysPerWeek(id) {
            const name = this.list_option_days_per_week.find(
                (target) => target.id === id
            )
            return name ? name.label : null
        },
        getNameById(id) {
            console.log(id)
            console.log(this.list_target)
            const target = this.list_target.find((target) => target.id === id)
            return target ? target.name : null
        },
        async getListTarget() {
            try {
                const response = await sendRequest(Api.survey.getListTarget)
                this.list_target = response.targets
            } catch (error) {
                console.error('Error fetching list of targets:', error)
            }
        },
        async suggestSurvey() {
            this.surveyData = getFromLocalStorage('surveyData')
            try {
                let storeSurvey = getFromLocalStorage('surveyData')
                const request_Params = {
                    phoneNumber: storeSurvey.phoneNumber || this.user.userInfoIdentifing.phoneNumber
                }
                const response = await sendRequest(
                    Api.survey.suggestSurvey,
                    null,
                    null,
                    request_Params
                )
                this.surveyData = response.item

            } catch (error) {
                console.log(error)
            }
        },
        BackStep() {
            this.$emit('handleBackStep')
        },
        NextStep() {
            this.$router.push('/plan-for-you')
        }
    }
}
</script>

<style scoped>
.step12 {
    height: 100vh;
}

.label {
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
}
.layout-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vertical-line {
    border-left: 1px solid #ccc;
    height: 600px;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn-blue {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: 700;
}

.btn {
    padding: 13px 120px;
    border-radius: 20px;
}

.title {
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}
.box {
    background-color: #f5f7f7;
    border-radius: 12px;
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
}
.bmi-rm,
.weight {
    display: flex;
    justify-content: space-between;
}

.title-rm,
.title-bmi {
    color: #191718;
    font-weight: 700;
    font-size: 14px;
}
.value-bmi,
.value-rm {
    color: #0276fa;
    font-weight: 900;
    font-size: 22px;
}
.img1 {
    height: 10px;
    object-fit: cover;
}
.img2 {
    width: 100%;
    object-fit: cover;
}
.img3 {
    object-fit: cover;
}
.avatar {
    object-fit: cover;
}
.title-weight {
    color: gray;
    font-size: 14px;
}
.img-weight {
    display: flex;
    flex-wrap: wrap;
}
.text-left {
    text-align: left;
}
.key {
    color: #191718;
    font-size: 16px;
    font-weight: 600;
}
.value {
    color: #0276fa;
    font-size: 16px;
    font-weight: 700;
}

.plan {
    display: flex;
    align-items: center;
}
.content-plan {
    margin-left: 10px;
    text-align: left;
    margin-bottom: 10px;
}
.key-plan,
.key-day {
    color: #191718;
    font-size: 14px;
}
.value-plan,
.value-day {
    color: #0276fa;
    font-size: 14px;
    font-weight: 700;
}
.day {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .btn {
        padding: 20px 150px;
        border-radius: 20px;
        margin-bottom: 30px;
    }
    .vertical-line {
        display: none;
    }
}
</style>

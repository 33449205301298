<template>
    <div class="step14 mt-5">
        <TitleSurvey :title="'TÊN KẾ HOẠCH'" />
        <div class="container">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-4">
                    <img src="@/assets/img/step13//img1.svg" class="img1" />
                    <img src="@/assets/img/step13//img2.svg" class="img2" />
                </div>

                <div class="col-sm-6">
                    <h4 class="h4">Giới thiệu</h4>
                    <p class="p">
                        {{dataPlan.description}}
                    </p>

                    <div class="row mt-3">
                        <div class="col-sm-6">
                            <h4>Tổng quan về chương trình</h4>
                            <hr class="vertical-line" />
                            <div class="overview">
                                <img
                                    src="@/assets/img/step14//img1.svg"
                                    class="img-overview"
                                />

                                <div class="title-overview">
                                    Tập tại {{dataPlan.practice_at == 1 ? 'phòng Gym' : 'nhà'}}
                                </div>
                            </div>
                            <div class="overview">
                                <img
                                    src="@/assets/img/step14//img2.svg"
                                    class="img-overview"
                                />

                                <div class="title-overview">Mục tiêu {{dataPlan.target_name}}</div>
                            </div>
                            <div class="overview">
                                <img
                                    src="@/assets/img/step14//img3.svg"
                                    class="img-overview"
                                />

                                <div class="title-overview">
                                    Tổng thời gian kế hoạch: {{dataPlan.plan_time}} tuần
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <h4>Lộ trình tập luyện</h4>
                            <hr class="vertical-line" />
                            <div
                                v-for="(week, index) in trainingWeeks"
                                :key="index"
                                class="training-route"
                            >
                                <img
                                    src="@/assets/img/step14/img4.svg"
                                    class="img-training-route"
                                />
                                <div class="title-training-route">
                                    {{ week.title }}
                                    <span>{{ week.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1"></div>
            </div>
            <div class="mt-4 text-center">
                <TitleSurvey :title="'CHỌN NGÀY TẬP TRONG TUẦN'" />
            </div>
            <div
                class="row mt-3 d-flex align-items-center justify-content-center"
            >
                <div
                    v-for="(item, index) in plan_schedules"
                    :key="index"
                    class="btn-white day m-2"
                    :class="{
                        selectedDay: planScheduleInfoId === item.id
                    }"
                    @click="handleDayClick(item.id)"
                >
                    {{ item.practice_weekdays_view }}
                </div>
            </div>

        </div>

        <div class="layout-btn">
            <CustomButton
                label="Quay lại"
                size="medium"
                type="text"
                class="btn btn-white"
                @click="BackStep"
            />
            <CustomButton
                label="THANH TOÁN"
                size="medium"
                type="text"
                class="btn btn-yellow"
                @click="handleConfirmPayFinal"
            />
        </div>
        <ElDialog
            :title="'Thông báo'"
            :message="messageDialog"
            :visible.sync="dialogVisible"
            :textBtn="'Thực hiện lại'"
            :showButton="false"
        />
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'

import sendRequest from '@/services'
import Api from '@/constants/Api'
import TitleSurvey from '@/components/TitleSurvey.vue'
import { getFromLocalStorage } from '@/utils/localStorage'
import { MessageBox } from 'element-ui'
import ElDialog from '@/components/ElDialog.vue'
import {getFromSessionStorage} from "@/utils/session";
import { mapState,mapMutations } from 'vuex'

export default {
    name: 'StepFourteen',
    components: { CustomButton, TitleSurvey, ElDialog },
    props: { handleNextStep: Function, handleBackStep: Function },
    data() {
        return {
            messageDialog: '',
            dialogVisible: false,
            trainingWeeks: [
                {
                    title: 'TUẦN 1',
                    description: 'Tuần 1'
                },
                { title: 'TUẦN 2', description: 'Tuần 2' },
                { title: 'TUẦN 3', description: 'Tuần 3' }
            ],
            days: [
                { id: 1, content: 'Thứ 2 - Thứ 3 - Thứ 4' },
                { id: 2, content: 'Thứ 2 - Thứ 3 - Thứ 4' },
                { id: 3, content: 'Thứ 2 - Thứ 3 - Thứ 4' }
            ],
            planScheduleInfoId: null,
            err: '',
            plan_schedules: [],
            plan_id: '',
            dataPlan: {},
            user : {},
        }
    },
    beforeMount() {
        this.user = getFromSessionStorage('user')
    },
    mounted() {
        this.suggestPlan()
    },
    methods: {
        ...mapMutations({
            setPlanSelect : 'setPlanSelect',
        }),

        async suggestPlan() {
            try {
                let storeSurvey = getFromLocalStorage('surveyData')
                const request_Params = {
                    'phone-number': this.user.userInfoIdentifing.phoneNumber
                }
                const response = await sendRequest(
                    Api.survey.suggestPlan,
                    null,
                    null,
                    request_Params
                )
                if (response.code === 200) {
                    const dataPlan =
                        response.item.find((item) => item.image_url) || {}

                    const { plan_schedules, id } = dataPlan

                    this.dataPlan = dataPlan
                    this.plan_id = id

                    this.plan_schedules = plan_schedules

                    this.setPlanSelect(dataPlan)
                } else {
                    this.dialogVisible = true
                    this.messageDialog = response.message
                }
            } catch (error) {
                console.log(error)
            }
        },
        async handleConfirmPay() {

            try {
                let storeSurvey = getFromLocalStorage('surveyData')
                const request_Data = {
                    item: {
                        phoneNumber: storeSurvey.phoneNumber,
                        plan_id: this.plan_id,
                        planScheduleInfoId: this.planScheduleInfoId
                    }
                }
                const response = await sendRequest(
                    Api.survey.confirmPay,
                    request_Data,
                    null
                )
                console.log(response)
            } catch (error) {
                console.log(error)
            }
        },
        async handleConfirmPayFinal() {
            if (this.planScheduleInfoId === null) {
                this.$alert('Vui lòng chọn ngày tập', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    center: true,
                    type: 'warning',
                    callback: action => {
                    }
                });
                return
            }

            this.dataPlan.planScheduleInfoId = this.planScheduleInfoId
            this.setPlanSelect(this.dataPlan)
            this.$router.push('/plan-package')
            // await this.handleConfirmPay()
            // try {
            //     let storeSurvey = getFromLocalStorage('surveyData')
            //     const request_Data = {
            //         paid_success: true,
            //         phone_number: storeSurvey.phoneNumber
            //     }
            //     const response = await sendRequest(
            //         Api.survey.confirmPayFinal,
            //         request_Data,
            //         null
            //     )
            //     this.dialogVisible = true
            //     if (response.code === 200) {
            //         this.messageDialog = response.message
            //     } else {
            //         this.messageDialog = response.message
            //     }
            // } catch (error) {
            //     console.log(error)
            // }
        },
        handleDayClick(id) {
            this.planScheduleInfoId = id
            console.log(this.planScheduleInfoId)
        },
        BackStep() {
            this.$router.push('/plan-for-you')
            this.$emit('handleBackStep')
        }
    }
}
</script>

<style scoped>
.step14 {
    height: 100vh;
}
.img1 {
    width: 100%;
    object-fit: cover;
    display: block;
    position: relative;
}
.img2 {
    position: absolute;
    left: 45%;
    top: 90%;
    object-fit: cover;
    width: 50%;
}

.h4 {
    color: #fcf9f4;
    letter-spacing: 3px;
    font-weight: 900;
}
.p {
    color: #fcf9f4;
    font-weight: 600;
    font-size: 14px;
}
.overview,
.training-route {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
h4 {
    color: #fcf9f4;
    font-size: 18px;
    font-weight: 900;
}
.img-overview {
    object-fit: cover;
    height: 15px;
}
.title-overview {
    margin-left: 15px;
    color: #fcf9f4;
}

.img-training-route {
    height: 10px;
    object-fit: cover;
}
.title-training-route {
    margin-left: 15px;
    color: #fcf9f4;
    font-size: 15px;
    font-weight: 600;
}
.title-training-route span {
    color: #fcf9f4;
    font-weight: 500;
    font-size: 13px;
    margin-left: 15px;
}
.vertical-line {
    border: 1px solid #fcf9f4;
    width: 20%;
}
.day {
    border-radius: 16px;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
}
.selectedDay {
    background-color: #0276fa !important;
    color: #ffffff !important;
}
.layout-btn {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 100px;
}

.btn-yellow {
    background-color: #fdb209;
    color: #2e2b2e;
    font-weight: 700;
}
::v-deep .el-button.is-disabled.el-button--text {
    background-color: #fdb209;
    color: #2e2b2e;
    font-weight: 700;
}

.btn-white {
    background-color: #fcf9f4;
    color: #2e2b2e;
    font-weight: 700;
}
.btn {
    padding: 13px 120px;
    border-radius: 8px;
}
</style>

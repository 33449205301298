import { getFromLocalStorage, saveToLocalStorage } from '@/utils/localStorage'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isCollapse: false,
        sessionId: '',
        sdt: '',

        surveyData: {},

        planSelect : null,
    },

    mutations: {
        setPlanSelect(state, newValue){
            state.planSelect = newValue
        },

        setIsCollapse(state, newValue) {
            state.isCollapse = newValue
        },

        setSessionId(state, sessionId) {
            state.sessionId = sessionId
        },
        setSdt(state, sdt) {
            state.sdt = sdt
        },

        updateSurveyData(state, { key, data }) {
            state.surveyData[key] = data
            saveToLocalStorage(state.surveyData, 'surveyData')
        },
        restoreSurveyData(state, data) {
            state.surveyData = data
        }
    },
    actions: {
        initializeSurveyStore({ commit }) {
            const storedData = getFromLocalStorage('surveyData')

            if (storedData) {
                commit('updateSurveyData', {
                    key: 'surveyData',
                    data: storedData
                })
            }
        },
        updateSessionId(context, sessionId) {
            context.commit('setSessionId', sessionId)
        },
        updateSdt(context, sdt) {
            context.commit('setSdt', sdt)
        }
    },
    getters: {},
    modules: {}
})

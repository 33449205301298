<template>
    <div>
        <el-input-number
            :style="{ width: `${width}${widthUnit}` }"
            :disabled="disabled"
            :clearable="clearable"
            :size="size"
            :value="value"
            :placeholder="placeholder"
            :min="minForInput"
            :max="max"
            :precision="precision"
            :step="step"
            v-bind="$attrs"
            @input="updateValue"
        ></el-input-number>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
        value: Number,
        disabled: Boolean,
        clearable: Boolean,
        size: String,
        min: Number,
        max: Number,
        precision: Number,
        step: Number,
        width: [String, Number],
        widthUnit: String // 'px' hoặc '%'
    },
    methods: {
        updateValue(newValue) {
            this.$emit('input', newValue) // Kích hoạt sự kiện "input" với giá trị mới
        }
    },
    computed: {
        minForInput() {
            return this.value !== null ? this.min : null
        }
    }
}
</script>

<style scoped>
::v-deep .el-input-number__decrease,
::v-deep .el-input-number__increase {
    display: none;
}
</style>

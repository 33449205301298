<template>
    <div class="join">
        <div class="container">
            <div class="layout">
                <div class="left">
                    Tham gia với chúng tôi ngay bây giờ để nhận được những ưu đãi
                    tốt nhất
                </div>
                <div class="right">
                    <CustomButton
                        label="Đăng ký ngay"
                        size="medium"
                        class="btn"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomButton from '@/components/CustomButton.vue'

export default {
    name: 'JoinGroup',
    components: { CustomButton },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style scoped>
.join {
    background-color: #313132;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.layout {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left {
    color: #ffffff;
    font-weight: 800;
    font-size: 16px;
}
.btn {
    background-color: #0276fa;
    color: #ffffff;
    font-weight: bold;
    padding: 15px 40px 15px 40px;
    border-radius: 10px;
    text-transform: uppercase;
}
@media (max-width: 768px) {
    .left {
        font-size: 15px;
    }
}
</style>
